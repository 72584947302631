<template>
  <popup class="trend-popup" :close-action="closePopup" :title="popupTitle">
    <template v-slot:content>
      <card class="trend-card" :has-content-padding="false">
        <bar-graph v-if="graphData" :with-sign='false' :graph-height="102" :bar-values="barValues"/>
      </card>
      <div v-if="isPresentingCurrentMonth">
        <div class="prediction-text">ממולץ להוציא החודש {{ adjustToCashflowStart }} עד <span class="bold">{{ predictedAmount }} ₪</span></div>
      </div>
      <expandable-section header-text="איך זה מחושב?" class="expandable-container" @expand-change="logExpandChanged">
        <template v-slot:content>
          <div class="category-reason">
            <span>
              אחרי שהחסרנו מההכנסות שלך את כל ההוצאות שצפויות לרדת החודש, נשאר לנו סכום להוצאות היומיומיות -
              <span class="bold">המשתנות</span>.
            </span>
            <div class="margin-top" v-if="predictedBalanceAtEndOfMonth <= 0">
              נראה שהחודש ההוצאות צפויות להיות גדולות מההכנסות, כדאי לעבור על התזרים ולוודא שהכל נכון.
            </div>
          </div>
        </template>
      </expandable-section>
      <div>
        <riseup-button :action="closePopup"
                       variant="primary"
                       title="הבנתי, תודה"
                       :size="'slim'"/>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapState } from 'vuex';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import cashflowViewConsts from '@/constants/cashflow-view';
import _ from 'lodash';
import { getEnvelopeTrendValues } from './cashflow-trends';
import CashflowTrends from './CashflowTrendsMixin';

export default {
  name: 'VariablesCashflowTrend',
  components: {
    Popup: BaseUI.Popup,
    Card: BaseUI.Card,
    ExpandableSection: BaseUI.ExpandableSection,
    RiseupButton: BaseUI.RiseupButton,
    BarGraph: BaseUI.BarGraph,
  },
  mixins: [CashflowTrends],
  data() {
    return {
      trendType: 'Variables',
    };
  },
  created() {
    this.trackCashflowTrendsOpened(this.trendType);
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth', 'monthFullName']),
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),

    graphData() {
      return getEnvelopeTrendValues(this.allCashflows, this.presentedBudgetDate, this.getVariablesTrendValuesForCashflow);
    },
    barValues() {
      return this.graphData ?? [];
    },
    category() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.variableExpense];
    },
    predictedAmount() {
      return moneyUtils.formatAmountWithFraction(this.category.recommendedOrExpectedAmount, 0);
    },
    popupTitle() {
      return 'הוצאות משתנות לפי חודשים';
    },
    adjustToCashflowStart() {
      if (this.cashflowStartDay === 1) {
        return '';
      }
      return `(${dateUtils.getMonthTimeFrame(this.presentedBudgetDate, this.cashflowStartDay)}) `;
    },
  },
  methods: {
    getVariablesTrendValuesForCashflow(cf, index) {
      return {
        value: cf.variableActualSum,
        secondaryValue: index === 0 && this.isPresentingCurrentMonth ? _.max([cf.variableBalancedAmount, 0]) : undefined,
        color: index === 0 ? BaseUI.Colors.riseupYellow : BaseUI.Colors.riseupLightYellow,
        focused: index === 0,
      };
    },
    closePopup() {
      this.closeCashflowTrendPopup(this.trendType);
    },
    logExpandChanged(newValue) {
      this.logCashflowTrendExpandChanged(this.trendType, newValue);
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-trends.styles";
</style>
