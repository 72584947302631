<template>
  <div class="cancel-tracking-category-confirmation-popup">
    <popup :title="`למחוק את קטגורית ${trackingCategory} מהתזרים?`"
           :buttons="[{ title: 'כן, למחוק מהתזרים', color: 'red', action: cancelTrackingCategoryApproved }]"
           :close-action="closeModal">
      <template v-slot:content v-if="upToNowAmount > 0">
        <div class="content">
          ההוצאות שמופיעות עכשיו ב<span class="move-from">{{trackingCategory}}</span> יעברו ל<span class="move-to">הוצאות משתנות</span>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'CancelTrackingCategoryConfirmationPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  props: {
    envelopeId: {
      type: String,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
    trackingCategory: {
      type: String,
      required: true,
    },
    upToNowAmount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions('editCashflow', ['cancelEnvelope', 'deactivateTrackingCategory']),
    ...mapActions('editCashflow', ['cancelEnvelope', 'deactivateTrackingCategory']),
    async cancelTrackingCategoryApproved() {
      Segment.trackUserInteraction('CancelTrackingCategoryConfirmationClicked', {
        trackingCategory: this.trackingCategory,
        envelopeId: this.envelopeId,
      });
      await this.deactivateTrackingCategory({ envelopeId: this.envelopeId, trackingCategoryId: this.categoryId });
      this.closeModal();
    },
    closeModal() {
      Segment.trackUserInteraction('CancelTrackingCategoryConfirmationCanceled', {
        trackingCategory: this.trackingCategory,
        envelopeId: this.envelopeId,
      });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .cancel-tracking-category-confirmation-popup {
    .content {
      line-height: 16px;

      .move-from {
        font-weight: bold;
        color: $riseup_blue;
      }

      .move-to {
        font-weight: bold;
        color: $riseup_yellow;
      }
    }
  }

</style>
