<template>
  <sticky-cta-page :cta-props="ctaProps" @click="next" class="sticky-cta">
    <image-header-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="header padding-bottom-ml">
          <div class="ri-large-body">התשלום עבר בהצלחה</div>
          <div class="subtitle ri-small-display">
            <span>איזה כיף</span>
            <span>שהצטרפת לתוכנית!</span>
          </div>
        </div>
      </template>
      <div class="padding-ml">
        <checked-items :items="checkedItems" flavour="green" class="margin-bottom-xs"/>
      </div>
    </image-header-page>
  </sticky-cta-page>
</template>
<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import CheckedItems from '@/base-components/CheckedItems.vue';
import InitSignupOfferings from '@/pages/responsive-pages/authenticated/signup-offerings/InitSignupOfferings.vue';
import Segment from '@/Segment';

export default {
  name: 'SignupOfferingPurchaseSuccess',
  components: {
    CheckedItems,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
  },
  mixins: [InitSignupOfferings],
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      rightActions: [],
      sticky: false,
    });
    Segment.trackUserInteraction('OfferingPurchaseSuccess_Shown', { offeringId: this.offerId });
  },
  data() {
    return {
      isLoading: false,
      imageHeaderProps: {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        title: '',
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        bottomSeparator: false,
        imgSrc: '/images/shapes/offers-shapes.svg',
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      },
      ctaProps: {
        buttonProps: {
          variant: 'primary',
          loading: this.isLoading,
          title: 'המשך',
        },
      },
    };
  },
  computed: {
    ...mapGetters('signupOfferings', ['successPurchaseCheckedItems']),
    checkedItems() {
      return this.successPurchaseCheckedItems(this.offerId);
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    async next() {
      this.isLoading = true;
      Segment.trackUserInteraction('OfferingPurchaseSuccess_NextClicked', { offeringId: this.offerId });
      await this.$router.push({ path: 'sr' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.sticky-cta {
  justify-content: space-between;

  .header {
    color: $riseup_white;
    width: 100%;
    display: flex;
    align-self: flex-end;
    align-items: flex-start;
    flex-direction: column;
    gap: $ri-spacing-xs;

    .subtitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

</style>
