<template>
  <share-button
                :filename="filename"
                :share-name="shareName"
                :share-component="shareComponent"
                :share-component-props="shareComponentProps"
                :share-impression="shareImpression"
                :show-popup="showPopup"
                :segment-extra-props="segmentExtraProps"
                v-slot="slotProps">
    <div class="tiny-share-button" v-on:click="slotProps.onClick">
      <img :src="require('@/assets/share_black_icon.svg')">
    </div>
  </share-button>
</template>

<script>

import ShareButton from '@/base-components/share/ShareButton';
import shareUtils from '@/utils/share';

export default {
  name: 'TinyShareButton',
  components: {
    ShareButton,
  },
  props: {
    shareComponent: {
      type: Object,
      required: true,
    },
    shareComponentProps: {
      type: Object,
    },
    shareName: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    shareImpression: {
      type: String,
      default: shareUtils.SHARE_IMPRESSION_OPTIONS.ON_SHOW,
    },
    showPopup: {
      type: Boolean,
      default: true,
    },
    segmentExtraProps: {
      type: Object,
      default: () => {},
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.tiny-share-button {
  display: inline-block;
  padding: 10px;
  color: $riseup_black;
  text-align: center;
  cursor: pointer;

  img {
    width: 12px;
  }
}
</style>
