<template>
  <div>
    <span class="ri-font-weight-bold">עו״ש בפלוס ותזרימים חיובים זה שילוב מעולה</span>, עכשיו כדאי להסתכל קדימה ולבחור במה להתמקד.
    <riseup-button class="margin-top-ml" title="לצעד הבא שלי" @click="$emit('click')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'PositiveCashflowsPositiveOsh',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
};
</script>

<style scoped lang="scss">

</style>
