<template>
  <cashflow-type-card
      :is-preview-mode="isPreviewMode"
      :card-type-id="CASHFLOW_TYPE"
      :can-edit-category="false"
      :card-title="'הוצאות משתנות'"
      :sub-tree="variableExpenses"
      :path-to-categories="pathToCategories"
      :cashflow-category="cashflowCategory"
      :initially-expanded=initiallyExpanded
      :recommended-label="recommendedLabel"
      :blocked="blocked"
      :previous-month="previousMonth"
      :out-of-date="outOfDate">
    <template v-slot:bottom>
      <expandable-category-details v-if="timeFrame === 'weekly'"
                                   :card-type-id="CASHFLOW_TYPE"
                                   :cashflow-category="cashflowCategory"
                                   :category-name="categoryName"
                                   :time-frame="timeFrame"
                                   :initially-expanded=initiallyExpanded
                                   :blocked="blocked"
                                   :categories="subCategories"
                                   :previous-month="previousMonth"
                                   :recommended-title="'נשאר להוציא'"
                                   :out-of-date="outOfDate"
                                   :has-update="variableExpenses.hasUpdate"
                                   :disable-editing="isPreviewMode"
                                   :category-type="categoryType"/>
      <expandable-cashflow-transactions v-else
                                        :card-type-id="CASHFLOW_TYPE"
                                        :category="subCategories[0]"
                                        :category-name="categoryName"
                                        :category-type="categoryType"
                                        :out-of-date="outOfDate"
                                        :disable-editing="isPreviewMode"
                                        :initially-expanded="false"
                                        :arrow-size="'medium'"/>
    </template>
  </cashflow-type-card>
</template>

<script>
import { mapState } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import CashflowTypeCard from './CashflowTypeCard';
import ExpandableCategoryDetails from './ExpandableCategoryDetails';
import ExpandableCashflowTransactions from './ExpandableCashflowTransactions';

export default {
  name: 'CashflowTypeBaseVariableExpense',
  components: {
    CashflowTypeCard,
    ExpandableCategoryDetails,
    ExpandableCashflowTransactions,
  },
  props: {
    initiallyExpanded: {
      type: Boolean,
      default: true,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
    timeFrame: {
      type: String,
      validator: val => ['monthly', 'weekly'].includes(val),
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    variableExpenses() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.variableExpense];
    },
    categoryName() {
      return cashflowViewConsts.CATEGORY_NAMES.VARIABLE;
    },
    categoryType() {
      return cashflowViewConsts.CATEGORY_NAMES.VARIABLE_EXPENSE;
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE;
    },
    CASHFLOW_TYPE() {
      return cashflowViewConsts.cashflowTypes.variableExpense;
    },
    pathToCategories() {
      return this.timeFrame === 'weekly' ? 'weekly.realExpenses.categories'
        : 'monthly.realExpenses.categories';
    },
    subCategories() {
      return this.timeFrame === 'weekly' ? this.variableExpenses.weekly.realExpenses.categories
        : this.variableExpenses.monthly.realExpenses.categories;
    },
    recommendedLabel() {
      return this.previousMonth ? undefined : 'מומלץ להוציא עד';
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-type-base.styles";
</style>
