import _ from 'lodash';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import moment from 'moment';
import cashflowHistory from '@/store/utilities/cashflow-history';
import cashflowViewConsts from '@/constants/cashflow-view';
import { DateLocales } from '@riseupil/common-utils';
import VariablesCashflowTrend from './VariablesCashflowTrend';
import SavingsCashflowTrend from './SavingsCashflowTrend';
import IncomeCashflowTrend from './IncomeCashflowTrend';
import FixedExpenseCashflowTrend from './FixedExpenseCashflowTrend';

const TREND_LOOKBACK = 6;
const FIXED_EXPENSE_CATEGORIES_LOOKBACK = 3;

/**
 * @param allCashflows - all cashflows for the current user
 * @param budgetDate - the budget date to get the trend for
 * @param getEnvelopeTrendValuesForCashflow - a mapping function that returns the trend values for a certain cashflow
 * @returns {{name: string; value: number; secondaryValue: number; color?: string; focused?: boolean}[]} - the trend data
 */
// eslint-disable-next-line import/prefer-default-export
export const getEnvelopeTrendValues = (allCashflows, budgetDate, getEnvelopeTrendValuesForCashflow) => {
  const cashflowsData = _getCashflowsDataForTrend(allCashflows, budgetDate, TREND_LOOKBACK);
  return _.chain(cashflowsData)
    .map((cashflow, index) => {
      const trendValues = getEnvelopeTrendValuesForCashflow(cashflow, index);
      return trendValues ? {
        name: _formatMonthName(cashflow.budgetDate),
        ...trendValues,
      } : null;
    })
    .compact() // remove falsey values (months without this category's envelope)
    .value();
};

/**
 * @param allCashflows - all cashflows for the current user
 * @param budgetDate - the budget date to get the trend for
 * @param getEnvelopeTrendValuesForCashflow - a mapping function that returns the trend values for a certain cashflow
 * @returns {{name: string; value: number; secondaryValue: number; color?: string; focused?: boolean}[]} - the trend data
 */
// eslint-disable-next-line import/prefer-default-export
export const getPastMonthSavingsTrendValues = (allCashflows, budgetDate, currentBudgetDate, getEnvelopeTrendValuesForCashflow) => {
  const cashflowsData = _getCashflowsDataForPastMonths(allCashflows, budgetDate, currentBudgetDate);
  return _.chain(cashflowsData)
    .map((cashflow, index) => {
      const trendValues = getEnvelopeTrendValuesForCashflow(cashflow, index);
      return trendValues ? {
        name: _formatMonthName(cashflow.budgetDate),
        ...trendValues,
      } : null;
    })
    .compact() // remove falsey values (months without this category's envelope)
    .value();
};

export const getAllFixedExpenseCategories = (allCashflows, budgetDate) => {
  const cashflowsData = _getCashflowsDataForTrend(allCashflows, budgetDate, FIXED_EXPENSE_CATEGORIES_LOOKBACK);
  return _.chain(cashflowsData)
    .flatMap(cashflow => Object.keys(cashflow.fixedTree.expenseCategories))
    .uniq()
    .value();
};

export const CATEGORIES_WITH_TREND = [
  cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING,
  cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
  cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_SAVING,
  cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME,
  cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
];

export const TREND_COMPONENTS = {
  [cashflowViewConsts.cashflowTypes.variableExpense]: VariablesCashflowTrend,
  [cashflowViewConsts.cashflowTypes.income]: IncomeCashflowTrend,
  [cashflowViewConsts.cashflowTypes.fixedExpense]: FixedExpenseCashflowTrend,
  savings: SavingsCashflowTrend,
};

export const RECOGNIZED_FIXED_EXPENSE_TREND_PAGES = ['משכנתא', 'ארנונה'];
export const RECOGNIZED_TRACKING_CATEGORY_TREND_PAGES = ['סופר'];

const _getCashflowsDataForTrend = (allCashflows, presentedBudgetDate, lookback) => cashflowHistory.getCashflowsForEnvelopeTrend(allCashflows,
  presentedBudgetDate, lookback)
  .map(cashflow => reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL));

const _getCashflowsDataForPastMonths = (allCashflows, presentedBudgetDate, currentBudgetDate) => cashflowHistory
  .getCashflowsForPastMonthsGraph(allCashflows, presentedBudgetDate, currentBudgetDate)
  .map(cashflow => reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL));

const _formatMonthName = budgetDate => moment(budgetDate)
  .locale('he')
  .format('MMM');
