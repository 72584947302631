<template>
  <card :has-header-padding="false" :has-content-padding="false" :has-footer-padding="false"
        class="offering-card padding-ml">
    <template v-slot:default>
      <div class="header">
        <div class="title">
          <div class="ri-medium-bold-headline">{{ description }}</div>
          <div class="ri-title grid-align-bottom">{{ planName }}</div>
        </div>
        <div v-if="price > 0" class="price-container ri-medium-bold-headline">
          <div class="price-amount">
            {{ price }} <span class="ri-bold-body">₪</span>
          </div>
          <span class="price-description grid-align-bottom ri-small-body">{{ isOneOffPayment ? 'חד פעמי' : 'חודשי' }}</span>
        </div>
        <img v-else class="free" src="images/shapes/free-flower.svg" />
      </div>
      <tag v-if="offeringStartDate" :background-color="tagBackgroundColor" :text="tagText" class="start-date-tag padding-xxs margin-top-sm" />
      <div class="bullets">
        <offering-details-bullet v-for="bullet in offeringBullets"
                                 :key="bullet"
                                 :text="bullet"
        />
      </div>
      <card-action-button class="margin-bottom-xxs margin-bottom-ml" @click="moreDetailsClicked" text="לפרטים נוספים"/>
      <riseup-button :title="buttonCtaText" :icon-props="iconProps" @click="next"/>
    </template>
  </card>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import CardActionButton from '@/base-components/card-parts/CardActionButton.vue';
import Segment from '@/Segment';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import moment from 'moment';
import dateUtils from '@/utils/dates';
import OfferingDetailsBullet from './OfferingDetailsBullet.vue';

export default {
  name: 'OfferingCard',
  components: {
    CardActionButton,
    Card: BaseUI.Card,
    OfferingDetailsBullet,
    RiseupButton: BaseUI.RiseupButton,
    Tag: BaseUI.Tag,
  },
  props: {
    planName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    offeringId: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    isOneOffPayment: {
      type: Boolean,
      required: true,
    },
    offeringStartDate: {
      type: String,
      required: false,
    },
    offeringBullets: {
      type: Array,
      required: true,
    },
    moreInfoLink: {
      type: String,
      required: true,
    },
    buttonCtaText: {
      type: String,
      required: true,
    },
    btnIcon: {
      type: String,
      required: false,
    },
    btnIconSize: {
      type: Number,
      required: false,
      default: 20,
    },
  },
  data() {
    return {
      ctaProps: {
        buttonProps: {
          loading: this.isLoading,
          title: 'המשך לחידוש המינוי',
        },
      },
      iconProps: {
        iconName: this.btnIcon,
        fill: true,
        size: `${this.btnIconSize}px`,
      },
      tagBackgroundColor: Colors.riseupGreen,
    };
  },
  methods: {
    next() {
      Segment.trackUserInteraction('OfferingCard_SelectClicked', { offeringId: this.offeringId });
      this.$emit('selectOffering', this.offeringId);
    },
    moreDetailsClicked() {
      Segment.trackUserInteraction('OfferingCard_MoreDetailsClicked', { offeringId: this.offeringId });
      window.open(this.moreInfoLink, '_blank');
    },
  },
  computed: {
    tagText() {
      return `נתחיל ב${dateUtils.getHebrewDayOfWeekFullName(this.offeringStartDate)}, ה-${moment(this.offeringStartDate).format('D.MM')}`;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings.scss';
@import '@riseupil/base-ui/src/scss/typography.scss';

.offering-card {
  background-color: $riseup_white;
  box-shadow: 0 12px 24px 0 rgba(32, 33, 37, 0.12);

  .header {
    color: $riseup_gray_100;
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: min-content min-content;

    grid-template-areas:
    "title price"
    "title price";

    .grid-align-bottom{
      align-self: flex-end;
      line-height: 26px;
    }

  .title {
      grid-area: title;
      display: grid;
      grid-template-rows: subgrid;
    }

    .price-container {
      grid-area: price;
      display: grid;
      grid-template-rows: subgrid;

      .price-amount {
        display: flex;
        align-items: baseline;
        gap: $ri-spacing-xxs;
      }
    }

    .free {
      grid-area: price;
      width: 56px;
      height: 56px;
    }
  }

  .start-date-tag {
    display: inline-block;
  }

  .bullets {
    display: flex;
    flex-direction: column;
    padding: $ri-spacing-sm 0;
    gap: $ri-spacing-xs;
  }
}

</style>
