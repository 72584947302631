<template>
  <card :has-header-padding="false" :has-content-padding="false" :has-footer-padding="false"
        class="offering-card padding-ml">
    <template v-slot:default>
      <img src="/images/nav/signup-offering-card-loader.svg"/>
    </template>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'OfferingCard',
  components: {
    Card: BaseUI.Card,
  },
};

</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings.scss';

.offering-card {
  background-color: $riseup_white;
  box-shadow: 0 12px 24px 0 rgba(32, 33, 37, 0.12);

  img {
    width: 100%;
  }
}

</style>
