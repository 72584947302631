<template>
  <my-progress-card :monthly-average="averageCashflows" card-type="תזרימים" @click="gotoBalanceSummary" >
    <template v-slot:asset>
      <div class="asset" :style="{ visibility: positiveCashflowsCount > 0 ? 'visible' : 'hidden'}">
        <img class="background" src="/images/my-progress/progress-cf.png" />
        <div class="number-of-months">
          <div >{{ positiveCashflowsCount }}</div>
          <div class="text">תזרימים חיוביים</div>
        </div>
      </div>
    </template>
  </my-progress-card>
</template>

<script>
import Segment from '@/Segment';
import MyProgressCard from './MyProgressCard';

export default {
  name: 'CashflowsCard',
  components: {
    MyProgressCard,
  },
  props: {
    averageCashflows: {
      type: Number,
      required: true,
    },
    positiveCashflowsCount: {
      type: Number,
      required: true,
    },
  },
  async created() {
    Segment.trackUserGot('MyProgress_CashflowsCard_Shown', { numberOfPositiveCFs: this.positiveCashflowsCount });
  },
  methods: {
    gotoBalanceSummary() {
      Segment.trackUserInteraction('MyProgress_CashflowsCard_Clicked');
      this.$router.push({ name: 'MyBalanceProgress' });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/typography";

.asset {
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .background {
    width: 140px;
  }

  .number-of-months {
    font-weight: $ri-font-weight-regular;
    font-size: $ri-font-size-28;
    margin-left: 16px;
    margin-top: 5px;
    width: 60%;
    text-align: center;
    position: absolute;
    z-index: 1;
    .text {
      font-size: $ri-font-size-14;
      line-height: $ri-font-size-16;
    }
  }
}

</style>
