<script>
import { mapState } from 'vuex';
import Segment from '../../../../../../../Segment';

export default {
  name: 'CashflowAnalytics',
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate']),
  },
  methods: {
    sectionClicked(cashflowType, event) {
      Segment.trackUserInteraction('CashflowViewCategoryClicked', {
        cashflowType,
        budgetDate: this.presentedBudgetDate,
        category: event.section,
        setExpanded: event.expanded,
      });
    },
    categorySectionsClicked(cashflowType, event) {
      Segment.trackUserInteraction('CashflowViewDetailsClicked', {
        cashflowType,
        budgetDate: this.presentedBudgetDate,
        title: event.title,
        setExpanded: event.expanded,
      });
    },
    cancelEnvelopeClicked(cashflowType, event) {
      Segment.trackUserInteraction('CancelEnvelopeDisapproveClicked', {
        envelopeDetails: {
          cashflowType,
          businessName: event.predictedTransaction.name,
          predictedAmount: event.predictedTransaction.predictedAmount,
          previousTransactionId: event.predictedTransaction.previousTransactionId,
          predictedTransactionDate: event.predictedTransaction.predictedTransactionDate,
        },
      });
    },
  },
};
</script>
