<template>
  <div>
    וואו, נראה שאפשר להתחיל להפקיד כסף לחיסכון ולהרוויח! <span class="ri-font-weight-bold">אפשר בממוצע בכל חודש להפקיד לחיסכון</span>
    <savings-recommendation :monthly-savings-recommendation="monthlySavingsRecommendation" />
    <riseup-button class="margin-top-ml" title="איך להתחיל לחסוך?" @click="$emit('click')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SavingsRecommendation from './SavingsRecommendation';

export default {
  name: 'StartSaving',
  components: {
    SavingsRecommendation,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    monthlySavingsRecommendation: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
