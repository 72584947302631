<template>
  <button class="card-action-button" @click="onClick" :style="styles" :class="{ 'out-of-date': outOfDate }">
    <tag v-if="labelText" :text="labelText" :background-color="labelBgColor" :text-color="labelColor" />
    <span class="ri-bold-label">{{ text }}</span>
    <icon v-bind="iconProps" />
  </button>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'CardActionButton',
  components: {
    Icon: BaseUI.Icon,
    Tag: BaseUI.Tag,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: false,
      default: BaseUI.Colors.riseupBlack,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: BaseUI.Colors.riseupWhite,
    },
    borderColor: {
      type: String,
      required: false,
      default: BaseUI.Colors.riseupGray0,
    },
    labelText: {
      type: String,
      required: false,
      default: '',
    },
    labelBgColor: {
      type: String,
      required: false,
      default: BaseUI.Colors.riseupBlack,
    },
    labelColor: {
      type: String,
      required: false,
      default: BaseUI.Colors.riseupWhite,
    },
    overrideIconProps: {
      type: Object,
      required: false,
      default: () => { return {}; },
    },
    outOfDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        border: `1px ${this.borderColor} solid`,
      };
    },
    iconProps() {
      return {
        iconName: 'arrow_back_ios_new',
        size: '12px',
        weight: 'bold',
        ...this.overrideIconProps,
      };
    },
  },
  methods: {
    onClick() {
      if (!this.outOfDate) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/border-radius";
.card-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $action-button-border-radius;
  height: 32px;
  cursor: pointer;
  padding: $ri-spacing-xs $ri-spacing-s $ri-spacing-xs $ri-spacing-xs;
  column-gap: $ri-spacing-xs;

  .label {
    border-radius: 2px;
    padding: 0 4px;
  }

  &.out-of-date {
    opacity: 0.6;
    filter: grayscale(100%);
    cursor: default;
  }
}
</style>
