<template>
  <bottom-sheet-overflow-button :bottom-sheet-component="sheetComponent" :component-props="{ menuItems }" />
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import SummaryAmountInfoPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/info-popups/SummaryAmountInfoPopup';
import InfoPopups
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/info-popups/InfoPopups';
import BottomSheetOverflowButton from '@/base-components/BottomSheetOverflowButton';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';

export default {
  name: 'SummaryAmountSectionMenu',
  components: {
    BottomSheetOverflowButton,
  },
  mixins: [InfoPopups],
  props: {
    showInfoPopups: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sheetComponent: SimpleBottomSheetMenu,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    emitOpenSectionInfoPopup() {
      this.trackInfoPopupClicked('summaryAmount');
      this.openModal({ component: SummaryAmountInfoPopup });
    },
  },
  computed: {
    menuItems() {
      return _.compact([
        this.showInfoPopups && {
          text: this.infoMenuItemText,
          iconProps: {
            iconName: 'info',
            fill: true,
          },
          onClick: this.emitOpenSectionInfoPopup,
        },
      ]);
    },
  },
};
</script>

<style scoped>
</style>
