import { client, clientWithoutErrorHandling } from '../http';

// eslint-disable-next-line import/prefer-default-export
export async function getCustomerSavingProfile() {
  return (await client.get('/api/get-savings-profile')).data;
}

export async function getCustomerSavingProfileWithoutErrorHandling() {
  return (await clientWithoutErrorHandling.get('/api/get-savings-profile')).data;
}
