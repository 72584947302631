<template>
  <slider v-if="orderedCategories.length" :slides-data="orderedCategories" auto-width :max-dots="maxDots" :first-slide-index="0">
    <template v-slot:default="{ slideData }">
      <card class="category-card" :key="slideData.categoryId" :has-content-padding="false">
        <div class="flex-row align-center padding-vertical-xs padding-horizontal-s"
             @click="onCategoryClick(slideData.categoryIdHash)"
             @keyup.enter="onCategoryClick(slideData.categoryIdHash)"
             tabindex="0">
          <img class="category-image"
               :src="require(`@/assets/tracking-categories/${getCategoryImgSrc(slideData.categoryName)}`)"
               alt="category-img"/>
          <div class="category-name margin-right-xxs margin-left-sm">{{ slideData.categoryName }}</div>
          <icon icon-name="arrow_back_ios_new" size="12px" weight="bold" :optical-size="48"/>
        </div>
      </card>
    </template>
  </slider>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'CategoryTrendsList',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
    Slider: BaseUI.Slider,
  },
  props: {
    availableCategoryTrends: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('createTrackingCategory', ['defaultCategories']),
    ...mapState('cashflowView', ['trackingCategoriesOrder']),
    orderedCategories() {
      return _.chain(this.availableCategoryTrends)
        .orderBy(this.availableCategoryTrends, c => {
          const index = this.trackingCategoriesOrder.indexOf(c.categoryId);
          return index > -1 ? index : 999;
        }, 'asc')
        .map(category => { return { ...category, key: category.categoryId }; })
        .value();
    },
    maxDots() {
      return this.orderedCategories.length > 5 ? 5 : undefined;
    },
  },
  methods: {
    onCategoryClick(categoryIdHash) {
      this.$router.push({
        name: 'CategoryProgressPage',
        params: { categoryIdHash },
      });
    },
    getCategoryImgSrc(categoryName) {
      const defaultCategory = this.defaultCategories.find(dc => dc.label === categoryName);
      return defaultCategory ? defaultCategory.iconName : 'other.png';
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/typography";
@import "~@riseupil/base-ui/src/scss/layout";
@import "~@riseupil/base-ui/src/scss/riseup-colors";

$category-max-width: 130px;

.category-card {
  cursor: pointer;
  height: 56px;
  margin-top: $ri-spacing-xs;
}

.category-image {
  height: 40px;
}

.category-name {
  @extend .ellipsis-text;
  max-width: $category-max-width;
  flex: 1;
}

</style>
