<template>
  <div class="cashflow-type-card">
    <card :data-cy="cardTypeId"
          :has-footer-padding="false" class="card-wrapper"
          :class="{'out-of-date': outOfDate }"
          :on-menu-opened="sectionMenuOpened"
          custom-menu>
      <template v-slot:default>
        <cashflow-type-details  :editable-category="categoryForActions"
                                :show-expense-progress="showExpenseProgress"
                                :card-title="cardTitle"
                                :sub-tree="subTree"
                                :is-income="isIncome"
                                :cashflow-category="cashflowCategory"
                                :previous-month="previousMonth"
                                :recommended-label="recommendedLabel"
                                :up-to-now-label="upToNowLabel"
                                :blocked="blocked"
                                :out-of-date="outOfDate"
                                :show-warning-if-exceeding-budget="showWarningIfExceedingBudget"/>
      </template>
      <template v-slot:menuItems v-if="hasMenu">
        <cashflow-income-section-menu v-if="shouldDisplayIncomeMenu" />
        <cashflow-category-section-menu v-else
                                        :show-info-popups="shouldShowInfoMenuItem"
                                        :show-category-trends="shouldShowTrendsMenuItem"
                                        :card-type-id="cardTypeId"
                                        :sub-tree="subTree"
                                        :can-edit="isEditableCategory"
                                        :category="categoryForActions"/>
      </template>
      <template v-slot:footer v-if="!blocked">
        <slot name="bottom" v-if="hasBottomSlot"></slot>
      </template>
    </card>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import cashflowViewConsts from '@/constants/cashflow-view';
import EventBus from '@/event-bus/event-bus';
import { mapState } from 'vuex';
import CashflowCategorySectionMenu
  from '../../../current-month/components/cashflow-edit/edit-reccuring/CashflowCategorySectionMenu.vue';
import CashflowIncomeSectionMenu
  from '../../../current-month/components/cashflow-edit/edit-reccuring/CashflowIncomeSectionMenu.vue';
import CashflowTypeDetails from './CashflowTypeDetails';
import InfoPopups from '../../../info-popups/InfoPopups';
import CashflowTrends from '../../../cashflow-trend/CashflowTrendsMixin';

export default {
  name: 'CashflowTypeCard',
  components: {
    Card: BaseUI.Card,
    CashflowCategorySectionMenu,
    CashflowIncomeSectionMenu,
    CashflowTypeDetails,
  },
  mixins: [InfoPopups, CashflowTrends],
  props: {
    cardTypeId: {
      type: String,
      required: true,
    },
    isPreviewMode: {
      type: Boolean,
      required: true,
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
    canEditCategory: {
      type: Boolean,
      default: true,
    },
    cardTitle: {
      type: String,
      required: true,
    },
    subTree: {
      type: Object,
      required: false,
    },
    isIncome: {
      type: Boolean,
      default: false,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    recommendedLabel: {
      type: String,
      required: false,
    },
    upToNowLabel: {
      type: String,
      required: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    pathToCategories: {
      type: String,
      default: 'categories',
    },
    showWarningIfExceedingBudget: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    EventBus.$on('scroll-to-card', this.scrollToCard);
  },
  beforeDestroy() {
    EventBus.$off('scroll-to-card', this.scrollToCard);
  },
  computed: {
    ...mapState('insights', ['initializedInsights']),
    categoryForActions() {
      return this.subCategories[0];
    },
    hasBottomSlot() {
      return !!this.$slots.bottom;
    },
    isEditableCategory() {
      return !this.outOfDate && !this.previousMonth && !this.blocked && this.canEditCategory;
    },
    subCategories() {
      return this.subTree ? _.get(this.subTree, this.pathToCategories) : [];
    },
    transactionIds() {
      return this.getCardTransactionIds(this.subCategories);
    },
    showExpenseProgress() {
      return this.cardTypeId !== 'excluded' && !!this.subTree;
    },
    shouldShowInfoMenuItem() {
      return this.shouldShowInfoPopups(this.previousMonth, this.blocked, this.outOfDate) && !this.shouldShowTrendsMenuItem;
    },
    shouldShowTrendsMenuItem() {
      return this.shouldShowCashflowTrend(this.cashflowCategory, this.blocked, this.outOfDate);
    },
    hasMenu() {
      return !this.isPreviewMode && (
        this.isEditableCategory
          || this.shouldShowInfoMenuItem
          || this.shouldShowTrendsMenuItem
      );
    },
    shouldDisplayIncomeMenu() {
      return this.cardTypeId === cashflowViewConsts.cashflowTypes.income && this.isPresentingCurrentMonth;
    },
  },
  methods: {
    sectionMenuOpened() {
      Segment.trackUserInteraction('OpenSectionMenuClicked', {
        trackingCategory: this.subTree ? this.subTree.categoryName : undefined,
        cardTypeId: this.cardTypeId,
      });
    },
    scrollToCard(cardTypeId, retries = 0) {
      if (cardTypeId !== this.cardTypeId) {
        return;
      }
      if (retries > 100) {
        Segment.trackUserGot('TransactionDeepLink_ScrollToTransaction_TimedOut', { cardTypeId: this.cardTypeId });
        return;
      }
      const uiIsReady = this.$el.offsetParent !== null && this.initializedInsights;
      if (!uiIsReady) {
        setTimeout(() => {
          this.scrollToCard(cardTypeId, retries + 1);
        }, 250);
        return;
      }
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import '../../../../../../../../../scss/mixins';

.cashflow-type-card {
  position: relative;

  .card-wrapper {
    padding-top: 18px;
    &.showing-edit-menu {
      opacity: 0.5;
    }

    &.out-of-date {
      color: $riseup_gray_2;
    }
  }
}

</style>
