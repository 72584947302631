<template>
  <expandable-summary-row @expanded="expanded" :has-update="showUpdateIndicator" :card-type-id="cardTypeId"
                          :initially-expanded="initiallyExpanded" :header-text="summaryRowText" :out-of-date="outOfDate" :type="categoryType">
    <template v-slot:content>
      <categories-table-header :out-of-date="outOfDate" :is-income="isIncome"
                               :show-recommended-amount="showRecommendedAmount" :recommended-title="recommendedTitle"/>
      <div v-for="category in categories" v-bind:key="category.label">
        <separator/>
        <expandable-cashflow-transactions :category="category"
                                          :card-type-id="cardTypeId"
                                          :use-category-id="useCategoryId"
                                          :time-frame="timeFrame"
                                          :out-of-date="outOfDate"
                                          :disable-editing="disableEditing"
                                          :category-name="categoryName"
                                          :category-type="categoryType"
                                          :arrow-size="'small'"
                                          :is-sub-category="true"
                                          :cashflow-category="cashflowCategory"
                                          :initially-expanded="initiallyExpandedTransactions"
                                          :is-predicted-transaction="isPredictedTransaction">
        </expandable-cashflow-transactions>
      </div>
    </template>
  </expandable-summary-row>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import ExpandableCashflowTransactions from './ExpandableCashflowTransactions';
import ExpandableSummaryRow from './ExpandableSummaryRow';
import CategoriesTableHeader from './CategoriesTableHeader';

export default {
  name: 'ExpandableCategoryDetails',
  components: {
    ExpandableCashflowTransactions,
    Separator: BaseUI.Separator,
    ExpandableSummaryRow,
    CategoriesTableHeader,
  },
  data() {
    return {
      didExpand: false,
    };
  },
  props: {
    cardTypeId: {
      type: String,
      required: true,
    },
    timeFrame: {
      type: String,
      default: 'monthly',
      validator: val => ['monthly', 'weekly'].includes(val),
    },
    useCategoryId: {
      type: Boolean,
      default: false,
    },
    isIncome: {
      type: Boolean,
      default: false,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    initiallyExpandedTransactions: {
      type: Boolean,
      default: false,
    },
    summaryRowTitle: {
      type: String,
      required: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      required: true,
    },
    isPredictedTransaction: {
      type: Boolean,
      default: false,
    },
    categoryName: {
      type: String,
      required: false,
    },
    recommendedTitle: {
      type: String,
      required: false,
    },
    categoryType: {
      type: String,
      required: false,
    },
    hasUpdate: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.initiallyExpanded) {
      this.didExpand = true;
    }
    EventBus.$on(`cashflow-type-card-expanded-${this.cardTypeId}`, this.setDidExpandTrue);
  },
  beforeDestroy() {
    EventBus.$off(`cashflow-type-card-expanded-${this.cardTypeId}`, this.setDidExpandTrue);
  },
  computed: {
    showRecommendedAmount() {
      return !this.previousMonth;
    },
    weeklyOrMonthly() {
      if (this.timeFrame === 'monthly') {
        return 'חודשי';
      }
      return 'שבועי';
    },
    summaryRowText() {
      return this.summaryRowTitle ? this.summaryRowTitle : `פירוט ${this.weeklyOrMonthly}`;
    },
    transactionIds() {
      return this.getCardTransactionIds(this.categories);
    },
    showUpdateIndicator() {
      return !this.didExpand && this.hasUpdate;
    },
  },
  methods: {
    setDidExpandTrue() {
      this.didExpand = true;
    },
    expanded(hasUpdate) {
      if (hasUpdate) {
        EventBus.$emit(`cashflow-type-card-expanded-${this.cardTypeId}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
