<template>
  <popup :buttons="buttons" :close-action="closeModal">
    <template v-slot:title>
      <div class="margin-vertical-m">
        <div>איך ההמלצה הזו מחושבת,</div>
        <div>ולמה לפעמים היא שונה מהממוצע?</div>
      </div>

    </template>
    <template v-slot:content>
      <div class="margin-bottom-m ri-large-body">
        <div>האלגוריתם שלנו מבוסס על ששת החודשים האחרונים ולוקח בחשבון אם יש חודשים עם הוצאות והכנסות מאוד חריגות.</div>
        <div>על הסכום שיוצא, המערכת מוסיפה מרווח ביטחון, כדי שנוכל לשמור על תזרים חיובי.</div>
      </div>
    </template>
  </popup>
</template>

<script>

import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'RecommendationHelper',
  components: {
    Popup: BaseUI.Popup,
  },
  data() {
    return {
      buttons: [{ action: this.closeModal, title: 'הבנתי, תודה' }],
    };
  },
  created() {
    Segment.trackUserGot('MyProgress_Overview_RecommendationHelper_Shown');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
  },
};
</script>

<style scoped lang="scss">
</style>
