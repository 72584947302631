<template>
  <div class="info-row">
    <div class="info-text" :class="{withAmount: !hideAmount}">
      <span class="ri-bold-title">{{title}}</span>
      <div>
        <span class="disclaimer-important ri-bold-label" v-if="!!importantSubtitlePart">{{importantSubtitlePart}}</span>
        <span class="disclaimer ri-label" v-if="!!subTitle">{{subTitle}}</span>
      </div>
    </div>
    <div v-if="!hideAmount">
      <span class="ri-bold-title">{{amount}}</span>
      <span class="ri-bold-body">₪</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PurchaseInfoLine',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    importantSubtitlePart: {
      type: String,
      required: false,
    },
    disclaimer: {
      type: String,
      required: false,
    },
    amount: {
      type: Number,
      required: true,
    },
    hideAmount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

.info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $riseup_black;

  .info-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .disclaimer-important {
      color: $riseup_blue;
    }

    .disclaimer {
      color: $riseup_gray_80;
      margin-top: $ri-spacing-xxs;
    }
  }
  .withAmount {
    max-width: 80%;;
  }
}

</style>
