<template>
  <div class="error-container">
    <div class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>

export default {
  name: 'EditAmountErrorContainer',
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.error-container {
  position: relative;
  width: 206px;

.error-message {
  position: absolute;
  top: 6px;
  color: $riseup_danger_red;
  font-size: 10px;
  font-family: SimplerPro;
  font-weight: bold;
}
}
</style>
