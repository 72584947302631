<template>
  <popup class="info-popup" :close-action="closePopup">
    <template v-slot:content>
      <slot/>
      <riseup-button :action="closePopup"
                     variant="primary"
                     title="הבנתי, תודה"
                     :size="'slim'"/>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'OnePageInfoPopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    cashflowType: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closePopup() {
      Segment.trackUserInteraction('MoreInfo_CloseClicked', {
        cashflowType: this.cashflowType,
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";

</style>
