<template>
  <loader v-if="loadingOffers" />
  <payment-form-page
    v-else
    :title="currentPurchasePageInfo.title"
    :purchase-button-text="currentPurchasePageInfo.buttonCtaText"
    :initial-values="initialValues"
    :price="currentPurchasePageInfo.total"
    :payment-date="currentPurchasePageInfo.paymentDate"
    :purchase-loading="purchaseLoading"
    :benefit-id="benefitIdIfNotRedeemed"
    @tokenization-success="purchase"
    @cancel="cancel"
  >
    <template v-slot:disclaimers>
      <div class="disclaimer ri-label">
        <span>
          תאריך החיוב הבא {{formattedFirstSubscriptionBillingDate }} על סך {{ price }} ₪
          - עלות המינוי בלבד בחיוב חודשי מתחדש.
        </span>
        <span v-if="!isDormant">החיוב יחל לאחר תקופת הניסיון.</span>
      </div>
      <div class="disclaimer ri-label">
        <span>אפשר לבטל את המינוי בכל עת ולקבל החזר יחסי.</span>
        <a class="link" href="https://www.riseup.co.il/termsofuse/">לתנאים המלאים</a>
      </div>
    </template>
  </payment-form-page>
</template>

<script>
import PaymentFormPage
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentForm/PaymentFormPage.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import { PERSONAL_INFO_FIELDS } from '@/store/modules/payment';
import Loader from '@/base-components/Loader.vue';
import InitSignupOfferings from '@/pages/responsive-pages/authenticated/signup-offerings/InitSignupOfferings.vue';
import Segment from '@/Segment';

export default {
  name: 'SignupOfferingPurchase',
  components: {
    Loader,
    PaymentFormPage,
  },
  mixins: [InitSignupOfferings],
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialValues: {},
      purchaseLoading: false,
    };
  },
  created() {
    this.initialValues = {
      [PERSONAL_INFO_FIELDS.firstName]: this.activeMemberFirstName,
      [PERSONAL_INFO_FIELDS.lastName]: this.activeMemberLastName,
      [PERSONAL_INFO_FIELDS.phoneNumber]: this.activeMemberPhoneNumber,
    };
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      sticky: false,
    });
    Segment.trackUserInteraction('OfferingPurchasePage_Shown', { offeringId: this.offerId });
  },
  computed: {
    ...mapGetters('session', ['activeMemberFirstName', 'activeMemberLastName', 'activeMemberPhoneNumber']),
    ...mapGetters('signupOfferings', ['purchasePageInfo', 'formattedFirstSubscriptionBillingDate']),
    ...mapGetters('benefits', ['discountedPrice']),
    ...mapGetters('subscriptionState', ['isDormant']),
    ...mapState('benefits', ['benefitId', 'redeemed']),
    currentPurchasePageInfo() {
      return this.purchasePageInfo(this.offerId);
    },
    benefitIdIfNotRedeemed() {
      return !this.redeemed ? this.benefitId : undefined;
    },
    price() {
      return this.benefitIdIfNotRedeemed ? this.discountedPrice : this.currentPurchasePageInfo.subscriptionOfferingPrice;
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('signupOfferings', ['updateSignupOfferingStatusChosen', 'purchaseSignupOffering']),
    async purchase(paymeToken) {
      Segment.trackUserInteraction('OfferingPurchasePage_PurchaseClicked', { offeringId: this.offerId });
      this.purchaseLoading = true;
      await this.purchaseSignupOffering({
        token: paymeToken?.token,
        signupOfferingId: this.offerId,
      });
      await this.updateSignupOfferingStatusChosen();
      this.purchaseLoading = false;
      await this.$router.push({ name: 'signupOfferingPurchaseSuccess', params: { offerId: this.offerId } });
    },
    cancel() {
      Segment.trackUserInteraction('OfferingPurchasePage_CancelClicked', { offeringId: this.offerId });
      this.$router.push({ name: 'signupOfferings', query: this.$route.query });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';

.link {
  display: block;
  color: $riseup_blue;
  text-decoration: none;

  &:visited {
    color: $riseup_blue;
  }
}
</style>
