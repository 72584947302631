import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import { CATEGORIES_WITH_TREND } from './cashflow-trends';

export default {
  data() {
    return {
      trendsMenuItemText: 'חודשים קודמים',
      trendsMenuItemIcon: 'bar_chart',
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['isPresentingCurrentMonth']),
    ...mapState('cashflowView', ['allCashflows', 'presentedBudgetDate', 'cashflowUITree']),
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    trackCashflowTrendsOpened(type, additionalData = {}) {
      Segment.trackUserInteraction('CashflowTrends_OpenClicked', {
        ...additionalData,
        isPresentingCurrentMonth: this.isPresentingCurrentMonth,
        type,
      });
    },
    shouldShowCashflowTrend(cashflowCategory, isBlocked, isOutOfDate) {
      return CATEGORIES_WITH_TREND.includes(cashflowCategory) && !isBlocked && !isOutOfDate
        && this.allCashflows && this.allCashflows.length > 1;
    },
    closeCashflowTrendPopup(type) {
      Segment.trackUserInteraction('CashflowTrends_CloseClicked', { type });
      this.closeModal();
    },
    logCashflowTrendExpandChanged(type, newValue) {
      Segment.trackUserInteraction('CashflowTrends_ExpandChanged', { type, newState: newValue ? 'Expanded' : 'Collapsed' });
    },
    logCashflowTrendsSwipe(cashflowCategoryType, trendType, naturalIndex, totalSlides) {
      Segment.trackUserInteraction('CashflowTrends_TrendsSwipe', {
        cashflowCategoryType,
        trendType,
        trendIndex: naturalIndex, // note: the index is 1-based
        totalSlides,
      });
    },
  },
};
