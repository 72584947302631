<template>
  <div class="ri-large-body padding-ml">
    <component :is="ProgressStatusComponent" :monthly-savings-recommendation="monthlySavingsRecommendation" @click="goToNextSteps" />
  </div>
</template>

<script>
import Segment from '@/Segment';
import { ProgressStatus } from '../../next-steps/my-progress-next-steps.consts';
import Trial from './Trial';
import SaveMore from './SaveMore';
import StartSaving from './StartSaving';
import PositiveCashflowsNegativeOsh from './PositiveCashflowsNegativeOsh';
import PositiveCashflowsPositiveOsh from './PositiveCashflowsPositiveOsh';
import NegativeCashflowsNegativeOsh from './NegativeCashflowsNegativeOsh';
import NegativeCashflowsPositiveOsh from './NegativeCashflowsPositiveOsh';

const progressStatusToComponent = {
  [ProgressStatus.TRIAL]: Trial,
  [ProgressStatus.SAVE_MORE]: SaveMore,
  [ProgressStatus.START_SAVING]: StartSaving,
  [ProgressStatus.POSITIVE_CASHFLOWS_NEGATIVE_OSH]: PositiveCashflowsNegativeOsh,
  [ProgressStatus.POSITIVE_CASHFLOWS_POSITIVE_OSH]: PositiveCashflowsPositiveOsh,
  [ProgressStatus.NEGATIVE_CASHFLOWS_NEGATIVE_OSH]: NegativeCashflowsNegativeOsh,
  [ProgressStatus.NEGATIVE_CASHFLOWS_POSITIVE_OSH]: NegativeCashflowsPositiveOsh,
};

export default {
  name: 'ProgressStatusCard',
  props: {
    progressStatus: {
      type: String,
      required: true,
      validator: val => Object.values(ProgressStatus).includes(val),
    },
    monthlySavingsRecommendation: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      ProgressStatus,
    };
  },
  created() {
    Segment.trackUserGot('MyProgress_ProgressStatus_Shown', { progressStatus: this.progressStatus });
  },
  computed: {
    ProgressStatusComponent() {
      return progressStatusToComponent[this.progressStatus];
    },
  },
  methods: {
    goToNextSteps() {
      Segment.trackUserInteraction('MyProgress_ProgressStatus_NextStepsClicked', { progressStatus: this.progressStatus });
      this.$router.push({ name: 'MyProgressNextSteps' });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
