<template>
  <popup class="trend-popup income" :close-action="closePopup" :title="popupTitle" remove-content-padding>
    <template v-slot:content>
      <slider :slides-data="slidesData" @on-slide="logSwipe" :first-slide-index="0">
        <template v-slot:default="{ slideData }">
          <card class="trend-card" :has-content-padding="false">
            <div class="trend-card-content">
              <div class="card-title">{{ slideData.key }}</div>
              <bar-graph v-if="graphData[slideData.key]" :with-sign='false' :graph-height="102"
                         :bar-values="barValues[slideData.key]"/>
            </div>
          </card>
        </template>
      </slider>
      <div class="vertical-padding">
        <div v-if="isPresentingCurrentMonth">
          <div class="prediction-text">צפי ההכנסות לחודש {{ monthFullName }} הוא: <span class="bold">{{
              predictedAmount
            }} ₪</span></div>
          והוא מבוסס על חודשים קודמים
        </div>
        <expandable-section header-text="איך זה מחושב?" class="expandable-container" @expand-change="logExpandChanged">
          <template v-slot:content>
            <div class="category-reason">
              אפשר לראות כמה כבר נכנס וכמה עוד צפוי להכנס עד סוף החודש. לפי זה נחשב כמה כסף נשאר להוציא.
            </div>
          </template>
        </expandable-section>
        <div>
          <riseup-button :action="closePopup"
                         variant="primary"
                         title="הבנתי, תודה"
                         :size="'slim'"/>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import cashflowViewConsts from '@/constants/cashflow-view';
import { getEnvelopeTrendValues } from './cashflow-trends';
import CashflowTrends from './CashflowTrendsMixin';

export default {
  name: 'IncomeCashflowTrend',
  components: {
    Popup: BaseUI.Popup,
    Card: BaseUI.Card,
    ExpandableSection: BaseUI.ExpandableSection,
    RiseupButton: BaseUI.RiseupButton,
    Slider: BaseUI.Slider,
    BarGraph: BaseUI.BarGraph,
  },
  mixins: [CashflowTrends],
  data() {
    return {
      trendType: 'Income',
      allCategoriesTrendTitle: 'כל ההכנסות',
      fixedIncomeTrendTitle: 'קבועות',
      variableIncomeTrendTitle: 'משתנות',
    };
  },
  created() {
    this.trackCashflowTrendsOpened(this.trendType);
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth', 'monthFullName']),
    graphData() {
      return this.incomeCategories.reduce((acc, curr) => {
        // create a trend graph for each category
        acc[curr] = getEnvelopeTrendValues(
          this.allCashflows,
          this.presentedBudgetDate,
          (cf, index) => this.getIncomeTrendValuesForCashflow(cf, index, curr),
        );
        return acc;
      }, {});
    },
    barValues() {
      return this.graphData ?? [];
    },
    category() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.income];
    },
    predictedAmount() {
      return moneyUtils.formatAmountWithFraction(this.category.recommendedOrExpectedAmount, 0);
    },
    incomeCategories() {
      return [this.allCategoriesTrendTitle, this.fixedIncomeTrendTitle, this.variableIncomeTrendTitle];
    },
    slidesData() {
      return this.incomeCategories.map(category => {
        return { key: category };
      });
    },
    popupTitle() {
      return 'הכנסות לפי חודשים';
    },
  },
  methods: {
    getIncomeTrendValuesForCashflow(cf, index, incomeType) {
      const trendValues = {
        color: index === 0 ? BaseUI.Colors.riseupGreen : BaseUI.Colors.riseupLightGreen,
        focused: index === 0,
      };
      switch (incomeType) {
      case this.fixedIncomeTrendTitle:
        trendValues.value = Math.abs(cf.incomeEnvelopesActualSum);
        trendValues.secondaryValue = (index === 0 && this.isPresentingCurrentMonth) ? Math.abs(cf.incomeEnvelopesBalanceSum) : undefined;
        break;
      case this.variableIncomeTrendTitle:
        trendValues.value = Math.abs(cf.variableIncomeSum);
        trendValues.secondaryValue = (index === 0 && this.isPresentingCurrentMonth) ? Math.abs(cf.variableIncomeBalancedAmount) : undefined;
        break;
      default:
      case this.allCategoriesTrendTitle:
        trendValues.value = Math.abs(cf.incomeEnvelopesActualSum + cf.variableIncomeSum);
        trendValues.secondaryValue = (index === 0 && this.isPresentingCurrentMonth)
          ? Math.abs(cf.incomeEnvelopesBalanceSum + cf.variableIncomeBalancedAmount) : undefined;
        break;
      }
      return trendValues;
    },
    closePopup() {
      this.closeCashflowTrendPopup(this.trendType);
    },
    logExpandChanged(newValue) {
      this.logCashflowTrendExpandChanged(this.trendType, newValue);
    },
    logSwipe(newIndex) {
      this.logCashflowTrendsSwipe(this.trendType, this.incomeCategories[newIndex], newIndex + 1, this.slidesData.length);
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-trends.styles";
</style>
