<template>
  <div>
    איזה כיף שהצטרפת! זה המקום לעקוב אחרי ההתקדמות שלך ולהבין מה הצעדים הבאים שלך.
    <riseup-button class="margin-top-ml" title="הצעדים הבאים שלי" @click="$emit('click')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'Trial',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
};
</script>

<style scoped lang="scss">

</style>
