<template>
  <offerings-page
    :offerings="offeringCards"
    :loading="loadingOffers"
    @selectOffering="goToOffering"
    @cancel="continueAlone"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OfferingsPage from '@/pages/responsive-pages/authenticated/navigation-pages/payment/OfferingsPage.vue';
import InitSignupOfferings from '@/pages/responsive-pages/authenticated/signup-offerings/InitSignupOfferings.vue';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'SignupOfferingsFlow',
  mixins: [InitSignupOfferings],
  components: {
    OfferingsPage,
  },
  created() {
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      rightActions: [],
      sticky: false,
    });
    Segment.trackUserInteraction('OfferingsPage_Shown', { offerings: JSON.stringify(this.offeringIds) });
  },
  computed: {
    ...mapGetters('signupOfferings', ['offeringCards']),
    offeringIds() {
      return this.offeringCards.map(offering => offering.id);
    },
  },
  methods: {
    ...mapActions('signupOfferings', ['updateSignupOfferingStatusChosen']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    goToOffering(offerId) {
      const { skipPurchaseInfoPage } = this.offeringCards.find(offeringCard => offeringCard.id === offerId);
      if (skipPurchaseInfoPage) {
        this.$router.push({ name: 'signupOfferingPurchase', params: { offerId }, query: this.$route.query });
      } else {
        this.$router.push({ name: 'signupOffering', params: { offerId }, query: this.$route.query });
      }
    },
    async continueAlone() {
      Segment.trackUserInteraction('OfferingsPage_SkipClicked');
      await this.updateSignupOfferingStatusChosen();
      return this.$router.push({ path: 'sr' });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
