<template>
  <sticky-cta-page :cta-props="ctaProps" @click="$emit('cancel')" class="sticky-cta">
    <image-header-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="header padding-bottom-ml">
          <div class="text ri-small-display">
            <span>להתחיל הרגל חדש</span>
            <span>קל יותר ביחד</span>
          </div>
          <div class="text subtitle ri-large-body">
            <div class="ri-large-body">{{subTitleText}}</div>
          </div>
        </div>
      </template>
      <template v-slot:overlayContent>
        <div class="offerings">
          <offering-card-loader v-if="loading"/>
          <offering-card v-else
                         v-for="offering in offerings"
                         :key="offering.id"
                         :button-cta-text="offering.buttonCtaText"
                         :btn-icon="offering.buttonCtaIcon"
                         :btn-icon-size="offering.btnIconSize"
                         :more-info-link="offering.moreInfoLink"
                         :offering-bullets="offering.offeringBullets"
                         :is-one-off-payment="offering.isOneOffPayment"
                         :price="offering.price"
                         :offering-start-date="offering.startDate"
                         :offering-id="offering.id"
                         :plan-name="offering.name"
                         :description="offering.description"
                         class="margin-bottom-ml"
                         @selectOffering="$listeners.selectOffering"
          />
        </div>
      </template>
    </image-header-page>
  </sticky-cta-page>
</template>
<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import OfferingCard from './OfferingCard/OfferingCard.vue';
import OfferingCardLoader from './OfferingCard/OfferingCardLoader.vue';

export default {
  name: 'OfferingsPage',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    OfferingCard,
    OfferingCardLoader,
  },
  props: {
    offerings: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      imageHeaderProps: {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        title: '',
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        bottomSeparator: false,
        imgSrc: '/images/shapes/offerings_page_header_graphic.svg',
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      },
    };
  },
  async created() {
    await this.initSubscriptionState();
  },
  computed: {
    ...mapGetters('subscriptionState', ['isDormant']),
    subTitleText() {
      return this.isDormant
        ? 'יוצאים לדרך ביחד עם תכניות הליווי הייחודיות שלנו'
        : 'לא משנה איזו תוכנית מתאימה לך יותר, העיקר שזה לא לבד.';
    },
    ctaProps() {
      return {
        buttonProps: {
          variant: 'tertiary',
          loading: this.isLoading,
          title: 'לא תודה, אמשיך בלי',
        },
      };
    },
  },
  methods: {
    ...mapActions('subscriptionState', ['initSubscriptionState']),
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';

//sticky cta component doesn't have the option to adjust the footer padding
.sticky-cta {
  ::v-deep .sticky-cta-footer {
    padding: 10px;
  }

  ::v-deep .shadow {
    bottom: 70px !important;
  }

  .header {
    color: $riseup_white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: $ri-spacing-s;
    text-align: start;
    width: 100%;

    .text {
      display: flex;
      flex-direction: column;

      &.subtitle {
        width: 88%;
      }
    }
  }

  .offerings {
    display: flex;
    flex-direction: column;

  }
}

</style>
