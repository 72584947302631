<template>
  <div v-if="loading && !savingsProfile" class="loader-container">
    <spinner/>
    <div class="margin-top-sm">מחשבים את ההתקדמות שלך...</div>
  </div>
  <dashboard-page v-else class="page flex-1 flex-column padding-bottom-xxl" :image-header-props="imageHeaderProps">
    <template v-slot:dashboard>
      <progress-status-card v-if="customerProgress.progressState" v-bind="customerProgress.progressState"/>
      <cta-content v-else :savings-profile="savingsProfile" :avg-cashflow="customerProgress.averageCashflows"
                   :has-savings="hasSavings"/>
    </template>
    <div>
      <div class="padding-horizontal-ml margin-top-xl">
        <div class="ri-label margin-bottom-sm">נתונים לפי 6 חודשים אחרונים</div>
        <cashflows-card class="margin-top-xxs" :average-cashflows="customerProgress.averageCashflows"
                        :positive-cashflows-count="customerProgress.positiveCashflowsCount"/>
        <savings-card v-if="hasSavings" :average-savings="customerProgress.averageSavings"
                      :total-savings="customerProgress.totalSavings"/>
        <savings-card-empty-state v-else/>
        <template v-if="hasAnyHighestChangeCategory">
          <div class="ri-bold-label margin-top-xl margin-bottom-sm">קטגוריות</div>
          <category-trends-summary-cards
              :positive-change-category="customerProgress.topCategoryTrends.highestPositiveChangeCategory"
              :negative-change-category="customerProgress.topCategoryTrends.highestNegativeChangeCategory"/>
        </template>
      </div>
      <template v-if="availableCategoryTrendsWithoutHighest.length > 0">
        <div class="ri-bold-label margin-top-xl margin-bottom-xs margin-horizontal-ml">עוד קטגוריות במעקב</div>
        <category-trends-list :available-category-trends="availableCategoryTrendsWithoutHighest"/>
      </template>
      <div class="padding-horizontal-ml margin-vertical-xl">
        <my-progress-total-accumulation-card />
      </div>
    </div>
  </dashboard-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import * as SavingsProfileApi from '@/api/SavingsProfileApi';
import Segment from '@/Segment';
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import ProgressStatusCard from './progress-status/ProgressStatusCard';
import CashflowsCard from './CashflowsCard.vue';
import SavingsCard from './SavingsCard.vue';
import CTAContent from './CTAContent';
import SavingsCardEmptyState from './SavingsCardEmptyState.vue';
import CategoryTrendsSummaryCards from '../categories/CategoryTrendsSummaryCards';
import CategoryTrendsList from '../categories/CategoryTrendsList';
import MyProgressTotalAccumulationCard from './MyProgressTotalAccumulationCard';

export default {
  name: 'MyProgressSummary',
  components: {
    MyProgressTotalAccumulationCard,
    ProgressStatusCard,
    CategoryTrendsList,
    CategoryTrendsSummaryCards,
    Spinner: BaseUI.Spinner,
    DashboardPage: BaseUI.Pages.DashboardPage,
    CashflowsCard,
    SavingsCard,
    SavingsCardEmptyState,
    CtaContent: CTAContent,
  },
  data() {
    return {
      loading: true,
      savingsProfile: undefined,
      imageHeaderProps: {
        imgSrc: '/images/my-progress/dashboard-header-shape.svg',
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
        bottomSeparator: false,
        title: 'ההתקדמות\nשלי',
        textColor: BaseUI.Colors.riseupDarkGreen,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITIONS.BOTTOM_LEFT,
      },
    };
  },
  async created() {
    Segment.trackUserGot('MyProgress_Overview_Entered');
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
    });
    const [savingsProfile] = await Promise.all([
      SavingsProfileApi.getCustomerSavingProfile(),
      this.initCustomerProgress(),
    ]);
    this.savingsProfile = savingsProfile;
    this.prefetchNextStepsRequiredData();
    this.loading = false;
  },
  computed: {
    ...mapState('customerProgress', ['customerProgress']),
    ...mapGetters('customerProgress', ['totalCashflowsAndSavingsSinceActivation', 'availableCategoryTrends']),
    ...mapState('cashflowView', ['cashflow']),
    ...mapState('challenge', ['activeChallenge']),
    ...mapState('applicationState', ['activationDate']),
    hasSavings() {
      return this.customerProgress?.totalSavings > 0;
    },
    availableCategoryTrendsWithoutHighest() {
      return this.availableCategoryTrends
        .filter(category => category.categoryId !== this.customerProgress.topCategoryTrends.highestPositiveChangeCategory?.categoryId
          && category.categoryId !== this.customerProgress.topCategoryTrends.highestNegativeChangeCategory?.categoryId);
    },
    hasAnyHighestChangeCategory() {
      return this.customerProgress.topCategoryTrends.highestPositiveChangeCategory
        || this.customerProgress.topCategoryTrends.highestNegativeChangeCategory;
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('customerProgress', ['initCustomerProgress']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('challenge', ['fetchActiveChallenge']),
    ...mapActions('planAhead', ['initEligibleForPlanAhead']),
    prefetchNextStepsRequiredData() {
      if (_.isEmpty(this.cashflow)) {
        this.initBudgets({ budgetDate: 'current' });
      }
      Promise.all([
        this.fetchActiveChallenge(),
        this.initCustomerProgress(),
        this.initEligibleForPlanAhead(),
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.my-progress-header {
  background-color: $riseup_beige;
  color: $riseup_dark_green;
  text-align: right;
  padding-top: $ri-spacing-ml;
  align-self: flex-start;
  padding-bottom: $ri-spacing-xl;

  .bold {
    font-weight: bold;
  }
}

.keep-spacing {
  white-space: pre;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: $riseup_beige;
}
</style>
