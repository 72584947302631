<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-tracking-category :subtree="subTree" :is-preview-mode="true"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">הוצאות סופר</span>
      <span>המערכת צופה את הוצאות הסופר לפי הממוצע בחודשים הקודמים. אפשר לקבוע יעד שבועי לסופר תחת
        <span class="bold">״פירוט שבועי״</span>.
        </span>
    </div>
  </one-page-info-popup>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'EconomyInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseTrackingCategory: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseTrackingCategory'),
  },
  props: {
    subTree: {
      type: Object,
      required: false,
    },
  },
  computed: {
    cashflowType() {
      return cashflowViewConsts.cashflowTypes.economy;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "info-popups";
</style>
