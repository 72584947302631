<template>
  <div class="expandable-type-transactions">
    <template v-if="isPredictedTransaction">
      <predicted-transaction v-for="transaction in transactions"
                             :key="getPredictedTransactionId(transaction)"
                             :ref="getPredictedTransactionId(transaction)"
                             :has-update="transaction.hasUpdate"
                             :predicted-transaction="transaction"
                             :can-edit-category="canEditTransaction"
                             :out-of-date="outOfDate"
                             :category-name="categoryName"
                             :category-id="useCategoryId ? categoryId : null">
      </predicted-transaction>
    </template>
    <template v-else>
      <transaction v-for="transaction in transactions"
                   :ref="transaction.transactionId"
                   :has-update="transaction.hasUpdate"
                   :transaction="transaction"
                   :can-edit-category="canEditTransaction"
                   :out-of-date="outOfDate"
                   :category-name="categoryName"
                   :type="categoryType"
                   :category-id="useCategoryId ? categoryId : null"
                   v-bind:key="transaction.transactionId">
      </transaction>
    </template>
  </div>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Segment from '@/Segment';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import { mapActions } from 'vuex';
import Transaction from './Transaction.vue';
import PredictedTransaction from './PredictedTransaction.vue';

export default {
  name: 'CashflowTypeTransactions',
  components: {
    Transaction,
    PredictedTransaction,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    cardTypeId: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
    useCategoryId: {
      type: Boolean,
      default: false,
    },
    isPredictedTransaction: {
      type: Boolean,
      default: false,
    },
    categoryType: {
      type: String,
      required: false,
    },
  },
  created() {
    EventBus.$on('open-transaction', this.openTransactionMenu);
    EventBus.$on('close-transaction', this.closeTransactionMenu);
  },
  beforeDestroy() {
    EventBus.$off('open-transaction', this.openTransactionMenu);
    EventBus.$off('close-transaction', this.closeTransactionMenu);
  },
  computed: {
    transactions() {
      return this.category.transactions;
    },
    categoryId() {
      return this.category.categoryId;
    },
    canEditTransaction() {
      return !(this.disableEditing || this.outOfDate);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    getPredictedTransactionId(transaction) {
      return transaction.actual ? transaction.actual.transactionId : transaction.sequenceId;
    },
    openTransactionMenu({ transactionId, fromSearch, fromDeepLink }) {
      const transaction = this.transactions.find(t => t.transactionId === transactionId
        || t.actual?.transactionId === transactionId
        || (!t.actual && t.sequenceId === transactionId));
      if (transaction) {
        this.$refs[transactionId]?.[0]?.toggleActions();
        Segment.trackUserGot('TransactionDeepLink_Success', { transactionId });
        if (fromDeepLink || fromSearch) {
          EventBus.$emit('scroll-to-card', this.cardTypeId);
          EventBus.$emit('expand-to-edit', { cardTypeId: this.cardTypeId, noScroll: true, selectedItemKey: transactionId });
        }
        if (fromSearch) {
          this.$nextTick(() => {
            performanceService.markEndAndLog(PerformanceMarks.CASHFLOW_SEARCH_RESULT_CLICK);
          });
        }
      }
    },
    closeTransactionMenu() {
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
</style>
