<template>
<image-header-page :image-header-props="imageHeaderProps">
  <template v-slot:overlayContent>
    <card :has-header-padding="false" :has-content-padding="false" :has-footer-padding="false" class="info-card">
      <template v-slot:header>
        <div class="header">
          <span class="ri-bold-title">{{planName}}</span>
          <span class="ri-title">{{description}}</span>
        </div>
      </template>
      <template v-slot:default>
        <div class="info">
          <tag v-if="offeringStartDate" :background-color="tagBackgroundColor" :text="tagText" class="start-date-tag padding-xxs" />
          <purchase-info-line
            :key="planPaymentSection.title"
            :title="planPaymentSection.title"
            :subTitle="planPaymentSection.subTitle"
            :disclaimer="planPaymentSection.disclaimer"
            :important-subtitle-part="planPaymentSection.importantSubtitlePart"
            :amount="planPaymentSection.amount"
            :hide-amount="planPaymentSection.hideAmount"
          />
          <purchase-info-line
            :key="selectedSubscriptionOffering.title"
            :title="selectedSubscriptionOffering.title"
            :subTitle="selectedSubscriptionOffering.subTitle"
            :important-subtitle-part="selectedSubscriptionOffering.importantSubtitlePart"
            :amount="selectedSubscriptionOffering.amount"
            :hide-amount="selectedSubscriptionOffering.hideAmount"
          />
        </div>
        <benefit-notification-box-v2 v-if="benefitId" class="benefit-box margin-bottom-ml" flavor="signup-offering" />
        <riseup-button :title="buttonText" @click="next"/>
      </template>
    </card>
  </template>
</image-header-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import InitSignupOfferings from '@/pages/responsive-pages/authenticated/signup-offerings/InitSignupOfferings.vue';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import dateUtils from '@/utils/dates';
import moment from 'moment';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import PurchaseInfoLine from './PurchaseInfoLine.vue';

export default {
  name: 'PurchaseInfoPage',
  components: {
    BenefitNotificationBoxV2,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
    PurchaseInfoLine,
    Tag: BaseUI.Tag,
  },
  mixins: [InitSignupOfferings],
  props: {
    planName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    planPaymentSection: {
      type: Object,
      required: true,
    },
    selectedSubscriptionOffering: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'נראה טוב!',
    },
    benefitId: {
      type: String,
      required: false,
    },
    offeringStartDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      imageHeaderProps: {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        title: 'אז כמה זה עולה לנו?',
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        bottomSeparator: false,
        imgSrc: '/images/shapes/offers-shapes.svg',
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      },
      tagBackgroundColor: Colors.riseupGreen,
    };
  },
  methods: {
    next() {
      this.$emit('next');
    },
  },
  computed: {
    tagText() {
      return `נתחיל ב${dateUtils.getHebrewDayOfWeekFullName(this.offeringStartDate)}, ה-${moment(this.offeringStartDate).format('D.MM')}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';

.info-card {
  padding: 0 $ri-spacing-ml $ri-spacing-ml $ri-spacing-ml;
  background-color: $riseup_white;
  box-shadow: 0 10px 20px 0 #A7B7FF80;

  .header {
    color: $riseup_blue;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $ri-spacing-ml 0;
    border-bottom: 1px solid $riseup_gray_20;
  }

  .info {
    display: flex;
    flex-direction: column;
    padding: $ri-spacing-ml 0;
    gap: $ri-spacing-ml;

    .start-date-tag {
      width: fit-content;
      display: inline-block;
    }
  }
  .benefit-box {
    color: $riseup_black;
  }
}
</style>
