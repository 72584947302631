<template>
  <div class="edit-amount-popup ">
    <popup :title="`כמה כסף צפוי לצאת על ${expenseCategory} ב${timeFrameTitle}?`"
           :buttons="[{ title: 'עדכון הסכום הצפוי', size: 'slim', action: updateEnvelopeAmountApprove }]"
           :close-action="cancelEdit">
      <template v-slot:content>
        <div class="content-container">
          <edit-amount-input
            data-cy="edit-amount-input"
            :updated-amount="updatedAmount"
            :placeholder="placeholder"
            :on-input="onInput"/>
          <edit-amount-error-container v-if="displayErrorMessage" :errorMessage="errorMessage"/>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapActions, mapState } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import EditCategoryAmountToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/EditCategoryAmountToaster';
import EditAmountErrorContainer
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/EditAmountErrorContainer';
import constants from '../../../../../../../../../constants/cashflow-view';
import Segment from '../../../../../../../../../Segment';
import money from '../../../../../../../../../utils/money';
import dateUtils from '../../../../../../../../../utils/dates';

export default {
  name: 'EditAmountPopup',
  components: {
    EditAmountErrorContainer,
    Popup: BaseUI.Popup,
    EditAmountInput: BaseUI.EditAmountInput,
  },
  data() {
    return {
      updatedAmount: null,
      displayErrorMessage: false,
    };
  },
  props: {
    expenseCategory: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['monthName']),
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),
    timeFrame() {
      return this.category.timeFrameDetails.timeFrame;
    },
    startDate() {
      return this.category.timeFrameDetails.startDate;
    },
    endDate() {
      return this.category.timeFrameDetails.endDate;
    },
    weeklyIndex() {
      return this.category.timeFrameDetails.weeklyIndex;
    },
    originalAmount() {
      return this.category.recommendedAmount;
    },
    placeholder() {
      return money.formatAmountWithFraction(this.originalAmount, 1);
    },
    envelopeId() {
      return this.category.envelopeId;
    },
    actualsSum() {
      return _.round(this.category.actualsSum, 1);
    },
    timeFrameTitle() {
      function timeFrameFormat(startDate, endDate) {
        return ` (מ־${startDate} עד ${endDate})`;
      }

      if (this.timeFrame === constants.envelopeTimeFrames.weekly) {
        const startDate = moment(this.startDate).format('D.M');
        const endDate = moment(this.endDate).format('D.M');
        return `שבוע ${this.weeklyIndex + 1}${timeFrameFormat(startDate, endDate)}`;
      }
      if (this.cashflowStartDay === 1) {
        return this.monthName;
      }
      const startDate = dateUtils.startOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      const endDate = dateUtils.endOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      return this.monthName + timeFrameFormat(startDate, endDate);
    },
    errorMessage() {
      if (this.updatedAmount < this.actualsSum) {
        return this.ERROR_MESSAGES.LESS_THAN_ACTUAL_SUM;
      } if (_.isNil(this.updatedAmount) || this.updatedAmount < 0) {
        return this.ERROR_MESSAGES.AMOUNT_IS_ZERO;
      }

      return null;
    },
    timeFrameDescription() {
      return this.timeFrame === constants.envelopeTimeFrames.weekly ? 'שבוע' : 'חודש';
    },
    ERROR_MESSAGES() {
      return {
        LESS_THAN_ACTUAL_SUM: `הסכום הצפוי צריך להיות מעל ${this.actualsSum} ₪ שכבר יצאו על ${this.expenseCategory} ה${this.timeFrameDescription}`,
        AMOUNT_IS_ZERO: 'הסכום הצפוי צריך להיות לפחות 0 ₪',
      };
    },
  },
  methods: {
    ...mapActions('editCashflow', ['updateEnvelopeAmount']),
    updateEnvelopeAmountApprove() {
      if (this.errorMessage) {
        Segment.trackUserInteraction('EditAmountErrorShown', {
          expenseCategory: this.expenseCategory,
          envelopeId: this.envelopeId,
          oldAmount: this.originalAmount,
          newAmount: this.updatedAmount,
        });
        this.displayErrorMessage = true;
        return;
      }
      Segment.trackUserInteraction('EditAmountApprovedClicked', {
        expenseCategory: this.expenseCategory,
        envelopeId: this.envelopeId,
        oldAmount: this.originalAmount,
        newAmount: this.updatedAmount,
      });
      this.updateEnvelopeAmount({ envelopeId: this.envelopeId, amount: this.updatedAmount });
      EventBus.$emit('open-toaster', {
        component: EditCategoryAmountToaster,
        props: {
          categoryName: this.expenseCategory,
          timeFrameTitle: this.timeFrameTitle,
        },
      });
      this.$emit('success');
      this.closeModal();
    },
    onInput(value) {
      this.displayErrorMessage = false;
      this.updatedAmount = value;
    },
    closeModal() {
      this.$emit('close');
    },
    cancelEdit() {
      Segment.trackUserInteraction('EditAmountCanceled', {
        expenseCategory: this.expenseCategory,
        envelopeId: this.envelopeId,
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .edit-amount-popup {
    .content-container {
      padding-bottom: 13px;

      .input-holder {
        .update-amount-input {
          color: $riseup_black;
          padding: 13px 8px 8px;
          width: 206px;
          height: 64px;
          font-size: 46px;
          line-height: 46px;
          font-family: SimplerPro;
          font-weight: bold;
          border-radius: 2px;
          box-shadow: inset 0 0 6px 0 $riseup-black;
          border: solid 1px $riseup_black;
          background-color: $riseup_white;
          outline: none;

          /* Remove up and down arrow */
          -moz-appearance: textfield;
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &::placeholder {
            color: $riseup_gray_0;
          }
        }

        .nis-currency {
          vertical-align: middle;
          font-weight: bold;
          font-size: 32px;
          color: $riseup_black;
        }
      }
    }
  }

</style>
