<template>
  <tiny-loader v-if="loadingOffers"/>
  <purchase-info-page
    v-else
    :plan-name="currentOffer.name"
    :description="currentOffer.description"
    :plan-payment-section="currentOffer.details"
    :subscription-payment-section="currentOffer.subscriptionDetails"
    :button-text="currentOffer.buttonText"
    :benefit-id="benefitIdIfNotRedeemed"
    :offering-start-date="currentOffer.startDate"
    :selected-subscription-offering="selectedSubscriptionOffering"
    @next="toPurchasePage"
  />
</template>

<script>
import InitSignupOfferings from '@/pages/responsive-pages/authenticated/signup-offerings/InitSignupOfferings.vue';
import PurchaseInfoPage from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PurchaseInfoPage.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'SignupOffering',
  components: {
    PurchaseInfoPage,
    TinyLoader: BaseUI.TinyLoader,
  },
  mixins: [InitSignupOfferings],
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      sticky: false,
    });
    Segment.trackUserInteraction('OfferingPage_Shown', { offeringId: this.offerId });
  },
  computed: {
    ...mapGetters('signupOfferings', ['purchaseInfo', 'selectedSubscriptionOffering']),
    ...mapState('benefits', ['benefitId', 'redeemed']),
    currentOffer() {
      return this.purchaseInfo(this.offerId);
    },
    benefitIdIfNotRedeemed() {
      return !this.redeemed ? this.benefitId : undefined;
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    toPurchasePage() {
      Segment.trackUserInteraction('OfferingPage_GoToPurchaseClicked', { offeringId: this.offerId });
      this.$router.push({ name: 'signupOfferingPurchase', params: { offerId: this.offerId }, query: this.$route.query });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
