<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-tracking-category :subtree="subTree" :is-preview-mode="true"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">{{ titleText }}</span>
      <span>{{ bodyText }}</span>
    </div>
  </one-page-info-popup>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'TrackingCategoryInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseTrackingCategory: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseTrackingCategory'),
  },
  props: {
    subTree: {
      type: Object,
      required: false,
    },
  },
  computed: {
    cashflowType() {
      return cashflowViewConsts.cashflowTypes.trackingCategory;
    },
    titleText() {
      return this.subTree.basedOnHistoryAverage ? 'קטגוריה מומלצת למעקב' : 'קטגוריה שבחרת לעקוב אחריה';
    },
    bodyText() {
      return this.subTree.basedOnHistoryAverage
        ? 'קטגוריה שהמערכת זיהתה שיש לך בה הוצאות כל חודש. הצפי החודשי נקבע לפי ממוצע החודשים הקודמים, אפשר גם לקבוע אותו בעצמך.'
        : 'אפשר להעביר אליה הוצאות שמתאימות לה מקטגוריות אחרות. הצפי נקבע לפי מה שהגדרת, ואפשר לשנות אותו תמיד.';
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
