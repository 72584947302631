<template>
  <cashflow-type-card
      :is-preview-mode="isPreviewMode"
      :card-type-id="CASHFLOW_TYPE"
      :can-edit-category="false"
      :card-title="'הכנסות'"
      :sub-tree="subtree"
      :is-income="true"
      :cashflow-category="cashflowCategory"
      :blocked="blocked"
      :previous-month="previousMonth"
      :out-of-date="outOfDate">
    <template v-slot:bottom>
      <expandable-category-details v-if="anyFixedIncome"
                                   :card-type-id="CASHFLOW_TYPE"
                                   :cashflow-category="cashflowCategory"
                                   :is-predicted-transaction="true"
                                   :category-name="fixedCategoryName"
                                   :blocked="blocked"
                                   :categories="income.fixedIncome.categories"
                                   :previous-month="previousMonth"
                                   :is-income="true"
                                   :summary-row-title="'פירוט הכנסות קבועות'"
                                   :has-update="income.fixedIncome.hasUpdate"
                                   :out-of-date="outOfDate"
                                   :disable-editing="isPreviewMode"/>
      <expandable-category-details v-if="hasVariableIncomePrediction || anyVariableIncome"
                                   :class="{ 'border-wrapper' : anyFixedIncome }"
                                   :card-type-id="CASHFLOW_TYPE"
                                   :cashflow-category="cashflowCategory"
                                   :category-name="variableCategoryName"
                                   :out-of-date="outOfDate"
                                   :disable-editing="isPreviewMode"
                                   :arrow-size="'medium'"
                                   :previous-month="previousMonth"
                                   :blocked="blocked"
                                   :summary-row-title="'פירוט הכנסות משתנות'"
                                   :is-income="true"
                                   :initiallyExpandedTransactions="true"
                                   :has-update="income.variableIncome.hasUpdate"
                                   :categories="income.variableIncome.categories"/>
    </template>
  </cashflow-type-card>
</template>

<script>
import { mapState } from 'vuex';
import CashflowTypeCard from './CashflowTypeCard';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';
import ExpandableCategoryDetails from './ExpandableCategoryDetails';

export default {
  name: 'CashflowTypeBaseIncome',
  components: {
    CashflowTypeCard,
    ExpandableCategoryDetails,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree', 'cashflow']),
    CASHFLOW_TYPE() {
      return cashflowViewConsts.cashflowTypes.income;
    },
    income() {
      return this.cashflowUITree[this.CASHFLOW_TYPE];
    },
    subtree() {
      return {
        ...this.income,
        categories: [
          ...this.income.fixedIncome.categories,
          ...this.income.variableIncome.categories,
        ],
      };
    },
    variableCategoryName() {
      return cashflowViewConsts.CATEGORY_NAMES.VARIABLE_INCOME;
    },
    fixedCategoryName() {
      return cashflowViewConsts.CATEGORY_NAMES.FIXED_INCOME;
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME;
    },
    anyVariableIncome() {
      return this.income.variableIncome.categories[0].transactions.length > 0;
    },
    hasVariableIncomePrediction() {
      return !!this.cashflow.variableIncomePredictionAmount;
    },
    anyFixedIncome() {
      return this.income.fixedIncome.categories.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../../../../../../scss/category-color-mixins';
@import "cashflow-type-base.styles";

.border-wrapper {
  border-top: 1px solid $riseup_gray_1;
}
</style>
