<template>
  <div class="cashflow-type-details">
    <div class="title-wrapper" :class="{ 'extra-padding' : !showExpenseProgress }">
        <div class="header">
          <span class="title ri-large-headline" :class="{ 'out-of-date': outOfDate }">{{ cardTitle }}</span>
          <active-challenge-badge v-if="activeCategoryChallenge" />
          <div v-else-if="isActiveMonthlyGoal" class="badge">
            <span>אתגר חודשי</span>
            <img :src="require('@/assets/challenge/active-monthly-goal.svg')">
        </div>
        </div>
      </div>
      <cashflow-expense-progress v-if="showExpenseProgress"
                        :recommended-or-expected-amount="Math.abs(subTree.recommendedOrExpectedAmount)"
                        :recommended-label="recommendedLabel"
                        :up-to-now-label="upToNowLabel"
                        :up-to-now-amount="Math.abs(subTree.upToNowAmount)"
                        :cashflow-category="cashflowCategory"
                        :show-warning-if-exceeding-budget="showWarningIfExceedingBudget"
                        :blocked="blocked"
                        :previous-month="previousMonth"
                        :is-income="isIncome"
                        :out-of-date="outOfDate"/>
      <card-action-button v-if="activeCategoryChallenge"
                         class="margin-top-m margin-bottom-ml"
                         @click="onChallengeClick"
                         text="לעמוד האתגר"
                         :text-color="this.textColor"/>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import CardActionButton from '@/base-components/card-parts/CardActionButton.vue';
import BaseUI from '@riseupil/base-ui';
import ActiveChallengeBadge from './ActiveChallengeBadge.vue';
import CashflowExpenseProgress from './CashflowExpenseProgress.vue';

export default {
  name: 'CashflowTypeDetails',
  components: {
    ActiveChallengeBadge,
    CardActionButton,
    CashflowExpenseProgress,
  },
  data() {
    return {
      textColor: BaseUI.Colors.riseupBlue,
    };
  },
  props: {
    editableCategory: {
      type: Object,
      required: false,
    },
    cardTitle: {
      type: String,
      required: true,
    },
    showExpenseProgress: {
      type: Boolean,
      required: true,
    },
    subTree: {
      type: Object,
      required: false,
    },
    isIncome: {
      type: Boolean,
      default: false,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    upToNowLabel: {
      type: String,
      required: false,
    },
    recommendedLabel: {
      type: String,
      required: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    showWarningIfExceedingBudget: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChallengeClick() {
      if (this.activeCategoryChallenge.link) {
        window.location.href = this.activeCategoryChallenge.link;
      }
    },
  },
  computed: {
    ...mapState('challenge', ['activeChallenge']),
    ...mapGetters('challenges', ['challengesDataWithLinks']),
    ...mapGetters('createTrackingCategory', ['defaultCategories']),
    isActiveMonthlyGoal() {
      return this.subTree && this.activeChallenge === this.subTree.categoryName && !this.previousMonth;
    },
    activeCategoryChallenge() {
      const expenseCategory = _.find(this.defaultCategories, { label: this.subTree.categoryName })?.value;
      return expenseCategory ? _.find(this.challengesDataWithLinks, { expenseCategory }) : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.cashflow-type-details {
  .title-wrapper {
    &.extra-padding {
      padding-bottom: 17px;
    }

    .header {
      display: flex;
      padding-left: 16px;

      .title {
        flex: 1;

        &.out-of-date {
          color: $riseup_gray_2;
        }
      }

      .edit-wrapper {
        color: $riseup_black;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        height: 36px;
        margin-bottom: 10px;
        padding: 10px 20px;
        position: relative;
        left: -20px;
        order: 1;
      }

      .edit-and-challenge-wrapper {
        display: flex;
      }

      .badge {
        color:$riseup_blue;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 1px;

        span {
          margin-top: 4px;
          margin-left: 4px;
        }
      }
    }
  }
}

</style>
