<template>
  <div class="padding-ml">
    <div class="ri-large-body">
      {{ changeAmountText.beforeName }}<span class="ri-font-weight-bold">{{ this.categoryName }}</span>{{ changeAmountText.afterName }}
    </div>
    <div class="ri-giant-display margin-top-ml margin-bottom-sm">{{
        changeAbsoluteAmount | formatAmountWithFraction(0) | shekel
      }}
    </div>
    <div class="change-percent">
      <img class="change-icon" :src="changeIconSrc" alt="change-trend"/>
      <div class="align-to-icon margin-right-xs">{{ changePercentText }}</div>
    </div>
  </div>
</template>

<script>
import moneyUtils from '@/utils/money';

export default {
  name: 'CategoryProgressOverview',
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    changeAbsoluteAmount: {
      type: Number,
      required: true,
    },
    changePercent: {
      type: Number,
      required: true,
    },
    positiveChange: { // עלייה
      type: Boolean,
      required: true,
    },
  },
  computed: {
    changeAmountText() {
      return this.positiveChange ? {
        beforeName: 'בחודשים האחרונים ההוצאות שלך בקטגוריית "',
        afterName: '" עלו ב:',
      } : {
        beforeName: 'בחודשים האחרונים ההוצאות שלך בקטגוריית "',
        afterName: '" ירדו ב:',
      };
    },
    formattedChangePercent() {
      return this.changePercent > 9.99 ? 'מעל 999%' : `${moneyUtils.formatAmountWithFraction(this.changePercent * 100, 0)}%`;
    },
    changePercentText() {
      return this.positiveChange ? `עלייה של ${this.formattedChangePercent} מ־3 החודשים הקודמים`
        : `ירידה של ${this.formattedChangePercent} מ־3 החודשים הקודמים`;
    },
    changeIconSrc() {
      return `/images/my-progress/${this.positiveChange ? 'positive' : 'negative'}-change-icon.svg`;
    },
  },
};
</script>

<style scoped lang="scss">
.change-percent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.align-to-icon {
  margin-top: 1px;
}
.change-icon {
  width: 16px;
  height: 16px;
}
</style>
