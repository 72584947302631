<template>
  <div class="ri-large-body padding-ml">
    <div v-if="computedCTA === CTATypes.TRIAL">
      <div>זה המקום לעקוב אחרי ההתקדמות שלך ולבחור את הצעד הבא שלך.</div>
      <div>כל חודש שמסתיים בתזרים חיובי, מגדיל את העו״ש ומאפשר לנו לחסוך יותר.</div>
      <div class="ri-large-bold-body margin-top-m">איך אפשר להתחיל?</div>
      <div v-if="hasActiveChallenge">
        הגדרת לעצמך יעד חודשי בקטגוריית {{ activeChallenge }}, זה צעד משמעותי בדרך לתזרימים חיוביים!
      </div>
      <div v-else>
        <div>מומלץ להציב יעד חודשי לאחת הקטגוריות ולהתחיל לצמצם בהוצאות!</div>
        <riseup-button class="margin-top-m" title="להציב יעד לקטגוריה" @click="openCategoryChallenge" />
      </div>
    </div>
    <div v-else-if="computedCTA === CTATypes.PROPOSED_MONTHLY_SAVING_AMOUNT">
      <div v-if="hasSavings">
        איזה יופי, נראה שאפשר
        <span class="ri-font-weight-bold">להגדיל את ההפקדה החודשית לחיסכון</span>
        בסכום של
      </div>
      <div v-else>
        וואו,נראה שאפשר להתחיל להפקיד כסף לחיסכון ולהרוויח!
        <span class="ri-font-weight-bold">אפשר להפקיד בכל חודש לחיסכון</span>
      </div>
      <div class="margin-top-s recommendation">
        <span class="ri-medium-display">{{ savingsProfile.proposedMonthlySavingAmount | formatAmountWithFraction(0) }}</span>
        <span class="ri-medium-headline">₪</span>
        <icon class="icon margin-right-xxs" icon-name="info" size="20px" :optical-size="20" @click="openRecommendationHelp"
              @keyup.enter="openRecommendationHelp" tabindex="0" />
      </div>
    </div>
    <div v-else-if="computedCTA === CTATypes.NEGATIVE_BALANCE_OR_POSITIVE_BALANCE_NEGATIVE_AVG_CASHFLOW">
      <div>זה המקום לעקוב אחרי ההתקדמות שלך ולבחור את הצעד הבא שלך.</div>
      <div class="ri-large-bold-body margin-top-m">מה אפשר לעשות עכשיו?</div>
      <div v-if="hasActiveChallenge">
        הגדרת לעצמך יעד חודשי בקטגוריית {{ activeChallenge }}, זה צעד משמעותי בדרך לתזרימים חיוביים!
      </div>
      <div v-else>
        <div v-if="negativeBalance">
          כדי לצאת מהמינוס, חשוב לשמור על תזרימים חיוביים לאורך זמן. מומלץ להציב יעד חודשי לאחת הקטגוריות ולהתחיל לצמצם בהוצאות!
        </div>
        <div v-else>כדי לשמור על תזרימים חיוביים לאורך זמן מומלץ להציב יעד חודשי לאחת הקטגוריות ולהתחיל לצמצם בהוצאות!</div>
        <riseup-button class="margin-top-m" title="להציב יעד לקטגוריה" @click="openCategoryChallenge" />
      </div>
    </div>
    <div v-else>
      <div>זה המקום לעקוב אחרי ההתקדמות שלך ולבחור את הצעד הבא שלך.</div>
      <div>התזרימים והחסכונות שלך בתקופה האחרונה יעזרו לך להבין את התמונה המלאה.</div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import BaseUI from '@riseupil/base-ui';
import ChallengeFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeFlow.vue';
import * as _ from 'lodash';
import cashflowHistory from '@/store/utilities/cashflow-history';
import Segment from '@/Segment';
import RecommendationHelper
  from './RecommendationHelper.vue';

const CTATypes = {
  TRIAL: 'trial',
  PROPOSED_MONTHLY_SAVING_AMOUNT: 'proposedMonthlySavingAmount',
  NEGATIVE_BALANCE_OR_POSITIVE_BALANCE_NEGATIVE_AVG_CASHFLOW: 'negativeBalanceOrPositiveBalanceNegativeAvgCashflow',
  DEFAULT: 'default',
};

export default {
  name: 'CTAContent',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Icon: BaseUI.Icon,
  },
  props: {
    savingsProfile: {
      type: Object,
      required: true,
    },
    avgCashflow: {
      type: Number,
      required: true,
    },
    hasSavings: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      CTATypes,
    };
  },
  async created() {
    if (_.isEmpty(this.cashflow)) {
      const budgetDate = cashflowHistory.getCurrentBudgetDate();
      await this.initBudgets({ budgetDate });
    }

    if (this.activeChallenge === null) {
      await this.fetchActiveChallenge();
    }
    Segment.trackUserGot('MyProgress_CTAContent_Shown', { computedCTA: this.computedCTA });
  },
  computed: {
    ...mapState('applicationState', ['activationDate']),
    ...mapState('challenge', ['activeChallenge']),
    ...mapState('cashflowView', ['cashflow']),
    negativeBalance() {
      return this.savingsProfile.currentOshBalance < 0;
    },
    positiveBalanceNegativeAvgCashflow() {
      return this.savingsProfile.currentOshBalance >= 0 && this.avgCashflow < 0;
    },
    isInTrialPeriod() {
      return this.activationDate && moment().isBefore(moment(this.activationDate).add(35, 'days'));
    },
    hasActiveChallenge() {
      return !_.isEmpty(this.activeChallenge);
    },
    computedCTA() {
      if (this.isInTrialPeriod) {
        return CTATypes.TRIAL;
      }
      if (this.savingsProfile.proposedMonthlySavingAmount) {
        return CTATypes.PROPOSED_MONTHLY_SAVING_AMOUNT;
      }
      if (this.negativeBalance || this.positiveBalanceNegativeAvgCashflow) {
        return CTATypes.NEGATIVE_BALANCE_OR_POSITIVE_BALANCE_NEGATIVE_AVG_CASHFLOW;
      }
      return CTATypes.DEFAULT;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('challenge', ['fetchActiveChallenge']),

    openCategoryChallenge() {
      Segment.trackUserGot('MyProgress_Overview_CategoryChallengeFlow_Clicked');
      this.openModal({
        component: ChallengeFlow,
        props: { sourceComponent: this.$options.name },
      });
    },

    openRecommendationHelp() {
      Segment.trackUserGot('MyProgress_Overview_RecommendationHelper_Clicked');
      this.openModal({
        component: RecommendationHelper,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/typography";

.recommendation {
  display: flex;
  align-items: flex-end;

  .icon {
    line-height: $ri-font-size-28;
  }

}
</style>
