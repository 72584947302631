<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'InitSignupOfferings',
  data() {
    return {
      loadingOffers: false,
    };
  },
  async created() {
    this.loadingOffers = true;
    const queryParamsOfferIds = Array.isArray(this.$route.query.offerId) ? this.$route.query.offerId : _.compact([this.$route.query.offerId]);
    await this.init(
      {
        offerIds: queryParamsOfferIds,
        featureFlagAvailableOfferings: this.offeringsExperiment,
        subscriptionState: this.subscriptionState,
      },
    );

    this.loadingOffers = false;
  },
  methods: {
    ...mapActions('signupOfferings', ['init']),
  },
  computed: {
    ...mapGetters('featureFlags', ['offeringsExperiment']),
    ...mapState('subscriptionState', ['subscriptionState']),
  },
};

</script>
