<template>
  <div class="category-trends-summary-cards">
    <category-trends-summary-card v-if="negativeChangeCategory"
                                  :change-percent="negativeChangeCategory.quarterlyChangePercentage"
                                  :category-id-hash="negativeChangeCategory.categoryIdHash"
                                  :category-name="negativeChangeCategory.categoryName" :is-positive="false" />
    <category-trends-summary-card v-if="positiveChangeCategory"
                                  :change-percent="positiveChangeCategory.quarterlyChangePercentage"
                                  :category-id-hash="positiveChangeCategory.categoryIdHash"
                                  :category-name="positiveChangeCategory.categoryName" is-positive />
  </div>
</template>

<script>
import CategoryTrendsSummaryCard from './CategoryTrendsSummaryCard.vue';

export default {
  name: 'CategoryTrendsSummaryCards',
  components: {
    CategoryTrendsSummaryCard,
  },
  props: {
    positiveChangeCategory: {
      type: Object,
      required: true,
    },
    negativeChangeCategory: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";

.category-trends-summary-cards {
  display: flex;
  column-gap: 16px;
}

</style>
