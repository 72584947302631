<template>
  <div>
    איזה יופי, נראה שאפשר <span class="ri-font-weight-bold">להגדיל בממוצע את ההפקדה לחיסכון</span> בכל חודש, בסכום של
    <savings-recommendation :monthly-savings-recommendation="monthlySavingsRecommendation" />
    <riseup-button class="margin-top-ml" title="מה כדאי לעשות?" @click="$emit('click')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SavingsRecommendation from './SavingsRecommendation';

export default {
  name: 'SaveMore',
  components: {
    SavingsRecommendation,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    monthlySavingsRecommendation: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
