<template>
  <div>
    <span class="ri-font-weight-bold">כדי לוודא שלא נכנס למינוס צריך לשמור על תזרימים חיוביים לאורך זמן</span>,
    עכשיו כדאי לבחור את הצעדים הבאים.
    <riseup-button class="margin-top-ml" title="לצעד הבא שלי" @click="$emit('click')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'NegativeCashflowsPositiveOsh',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
};
</script>

<style scoped lang="scss">

</style>
