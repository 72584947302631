<template>
  <my-progress-card :monthly-average="averageSavings" card-type="חסכונות" @click="gotoSavingsSummary" >
    <template v-slot:asset>
      <div class="asset">
        <img class="background" src="/images/my-progress/progress-savings.svg" />
        <div class="number-of-months">
          <div><span class="ri-large-body ">₪</span>{{ totalSavings | formatAmountWithFraction(0) }}</div>
          <div class="ri-label text margin-left-xxs">סה״כ חסכת</div>
        </div>
      </div>
    </template>
  </my-progress-card>
</template>

<script>
import Segment from '@/Segment';
import MyProgressCard from './MyProgressCard';

export default {
  name: 'SavingsCard',
  components: {
    MyProgressCard,
  },
  props: {
    averageSavings: {
      type: Number,
      required: true,
    },
    totalSavings: {
      type: Number,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('MyProgress_Savings_Card_Shown', { savingsExists: true });
  },
  methods: {
    gotoSavingsSummary() {
      Segment.trackUserInteraction('MyProgress_SavingsCard_Clicked');
      this.$router.push({ name: 'MySavingsProgress' });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/typography";
@import "~@riseupil/base-ui/src/scss/spacings";

.asset {
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .background {
    width: 140px;
  }
  .number-of-months {
    font-weight: $ri-font-weight-regular;
    font-size: $ri-font-size-24;
    margin-left: $ri-spacing-s;
    margin-top: $ri-spacing-ml;
    width: 90%;
    text-align: center;
    position: absolute;
    z-index: 1;
    .text {
      line-height: 12px;
    }
  }
}
</style>
