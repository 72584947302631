import { render, staticRenderFns } from "./CashflowTypeBaseIncome.vue?vue&type=template&id=799b1b92&scoped=true&"
import script from "./CashflowTypeBaseIncome.vue?vue&type=script&lang=js&"
export * from "./CashflowTypeBaseIncome.vue?vue&type=script&lang=js&"
import style0 from "./CashflowTypeBaseIncome.vue?vue&type=style&index=0&id=799b1b92&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799b1b92",
  null
  
)

export default component.exports