<template>
  <bottom-sheet-overflow-button :bottom-sheet-component="sheetComponent" :component-props="{ menuItems }" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import _ from 'lodash';
import BottomSheetOverflowButton from '@/base-components/BottomSheetOverflowButton';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';
import EditTrackingCategoryPopup from '../EditTrackingCategoryPopup';
import EditAmountPopup from '../EditAmountPopup';
import Segment from '../../../../../../../../../../Segment';
import CancelTrackingCategoryConfirmationPopup from './CancelTrackingCategoryConfirmationPopup.vue';
import TrackingCategoryInfoPopup from '../../../../info-popups/TrackingCategoryInfoPopup';
import InfoPopups from '../../../../info-popups/InfoPopups';
import TrackingCategoryCashflowTrend from '../../../../cashflow-trend/TrackingCategoryCashflowTrend';
import CashflowTrends from '../../../../cashflow-trend/CashflowTrendsMixin';
import { TREND_COMPONENTS } from '../../../../cashflow-trend/cashflow-trends';

export default {
  name: 'CashflowCategorySectionMenu',
  components: {
    BottomSheetOverflowButton,
  },
  mixins: [InfoPopups, CashflowTrends],
  props: {
    cardTypeId: {
      type: String,
      required: true,
    },
    subTree: {
      type: Object,
      required: false,
    },
    category: {
      type: Object,
      required: false,
    },
    showInfoPopups: {
      type: Boolean,
      required: true,
    },
    showCategoryTrends: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sheetComponent: SimpleBottomSheetMenu,
    };
  },
  computed: {
    // TODO: Remove after tests
    ...mapGetters('featureFlags', ['showEditTrackingCategoryName']),
    categoryName() {
      return this.subTree ? this.subTree.categoryName : undefined;
    },
    envelopeId() {
      return this.subTree ? this.subTree.envelopeId : undefined;
    },
    upToNowAmount() {
      return this.subTree ? this.subTree.upToNowAmount : undefined;
    },
    canEditTrackingCategoryName() {
      return this.showEditTrackingCategoryName
          // need to find a better way to distinct tracking categories
          && this.category?.categoryId
          && !['economy', 'savings'].includes(this.cardTypeId);
    },
    menuItems() {
      return _.compact([
        this.showInfoPopups && {
          text: this.infoMenuItemText,
          iconProps: {
            iconName: 'info',
            fill: true,
          },
          onClick: this.emitOpenSectionInfoPopup,
        },
        this.showCategoryTrends && {
          text: this.trendsMenuItemText,
          iconProps: {
            iconName: this.trendsMenuItemIcon,
          },
          onClick: this.emitOpenTrend,
        },
        this.canEdit && {
          text: 'עריכת סכום',
          iconProps: {
            iconName: 'edit',
            fill: true,
          },
          onClick: this.emitOpenEditTrackingCategoryAmountPopup,
        },
        this.canEditTrackingCategoryName && {
          text: 'עריכת שם',
          iconProps: {
            iconName: 'text_fields',
          },
          onClick: this.emitOpenEditTrackingCategoryNamePopup,
        },
        this.canEdit && {
          text: 'מחיקה מהתזרים',
          iconProps: {
            iconName: 'delete',
            fill: true,
          },
          onClick: this.emitOpenCancelTrackingCategoryConfirmationPopup,
        },
      ]);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    emitOpenSectionInfoPopup() {
      if (this.INFO_COMPONENTS[this.cardTypeId]) {
        this.trackInfoPopupClicked(this.cardTypeId);
        this.openModal({
          component: this.INFO_COMPONENTS[this.cardTypeId],
          props: { subTree: this.subTree },
        });
      } else {
        this.trackInfoPopupClicked(cashflowViewConsts.cashflowTypes.trackingCategory);
        this.openModal({
          component: TrackingCategoryInfoPopup,
          props: { subTree: this.subTree },
        });
      }
    },
    emitOpenTrend() {
      if (TREND_COMPONENTS[this.cardTypeId]) {
        this.openModal({ component: TREND_COMPONENTS[this.cardTypeId] });
      } else {
        this.openModal({
          component: TrackingCategoryCashflowTrend,
          props: { categoryId: this.category.categoryId },
        });
      }
    },
    emitOpenCancelTrackingCategoryConfirmationPopup() {
      Segment.trackUserInteraction('SectionMenu_CancelTrackingCategoryClicked', {
        trackingCategory: this.categoryName,
      });
      this.openModal({
        component: CancelTrackingCategoryConfirmationPopup,
        props: {
          trackingCategory: this.categoryName,
          categoryId: this.category.categoryId,
          envelopeId: this.envelopeId,
          upToNowAmount: this.upToNowAmount,
        },
      });
    },
    emitOpenEditTrackingCategoryAmountPopup() {
      Segment.trackUserInteraction('SectionMenu_EditTrackingCategoryAmountClicked', {
        trackingCategory: this.categoryName,
      });
      this.openModal({
        component: EditAmountPopup,
        props: {
          expenseCategory: this.categoryName,
          category: this.category,
        },
      });
    },
    emitOpenEditTrackingCategoryNamePopup() {
      Segment.trackUserInteraction('SectionMenu_EditTrackingCategoryNameClicked', {
        trackingCategory: this.categoryName,
      });
      this.openModal({
        component: EditTrackingCategoryPopup,
        props: {
          categoryName: this.cardTypeId,
          category: this.category,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.cashflow-category-menu-items {
  display: contents;
}

</style>
