<template>
  <bottom-sheet-overflow-button :bottom-sheet-component="sheetComponent" :component-props="{ menuItems }" />
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';
import BottomSheetOverflowButton from '@/base-components/BottomSheetOverflowButton';
import Segment from '../../../../../../../../../../Segment';
import CashflowTrends from '../../../../cashflow-trend/CashflowTrendsMixin';
import { TREND_COMPONENTS } from '../../../../cashflow-trend/cashflow-trends';
import predictedVariableIncomeConsts from '../edit-predicted-variable-income/consts';
import EditPredictedVariableIncomePopup from '../edit-predicted-variable-income/EditPredictedVariableIncomePopup';
import PredictedVariableIncomeInfoPopup from '../edit-predicted-variable-income/PredictedVariableIncomeInfoPopup';

export default {
  name: 'CashflowIncomeSectionMenu',
  components: {
    BottomSheetOverflowButton,
  },
  mixins: [CashflowTrends],
  data() {
    return {
      sheetComponent: SimpleBottomSheetMenu,
    };
  },
  computed: {
    wasInfoPopupDisplayed() {
      return Boolean(localStorage.getItem(predictedVariableIncomeConsts.INFO_POPUP_DISPLAYED_KEY));
    },
    menuItems() {
      return _.compact([
        {
          text: 'לעדכן צפי הכנסות משתנות',
          onClick: this.openEditPredictedVariableIncomePopup,
          iconProps: {
            iconName: 'edit',
            fill: true,
          },
        },
        {
          text: this.trendsMenuItemText,
          onClick: this.emitOpenTrend,
          iconProps: {
            iconName: this.trendsMenuItemIcon,
          },
        },
      ]);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    emitOpenTrend() {
      this.openModal({ component: TREND_COMPONENTS[cashflowViewConsts.cashflowTypes.income] });
    },
    openEditPredictedVariableIncomePopup() {
      Segment.trackUserInteraction('SectionMenu_openEditPredictedVariableIncomePopup_Clicked', {
        trackingCategory: this.categoryName,
      });
      this.openModal({
        component: this.wasInfoPopupDisplayed ? EditPredictedVariableIncomePopup : PredictedVariableIncomeInfoPopup,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.cashflow-category-menu-items {
  display: contents;
}

</style>
