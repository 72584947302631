<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-saving-category :is-preview-mode="true"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">חסכונות</span>
      הוצאות שהמערכת זיהתה כהפקדות לחיסכון או כאלה שבחרת להעביר בעצמך.
    </div>
  </one-page-info-popup>
</template>

<script>

import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'SavingsInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseSavingCategory: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSavingCategory'),
  },
  computed: {
    cashflowType() {
      return 'savings';
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
