<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import cashflowViewConsts from '@/constants/cashflow-view';
import VariablesInfoPopup from './VariablesInfoPopup';
import EconomyInfoPopup from './EconomyInfoPopup';
import FixedExpenseInfoPopup from './FixedExpenseInfoPopup';
import ExcludedInfoPopup from './ExcludedInfoPopup';
import IncomeInfoPopup from './IncomeInfoPopup';
import SavingsInfoPopup from './SavingsInfoPopup';

export default {
  name: 'InfoPopups',
  data() {
    return {
      infoMenuItemText: 'איך זה מחושב?',
      infoMenuItemIcon: 'icons/info-icon-grey.svg',
      INFO_COMPONENTS: {
        [cashflowViewConsts.cashflowTypes.variableExpense]: VariablesInfoPopup,
        [cashflowViewConsts.cashflowTypes.fixedExpense]: FixedExpenseInfoPopup,
        [cashflowViewConsts.cashflowTypes.excluded]: ExcludedInfoPopup,
        [cashflowViewConsts.cashflowTypes.income]: IncomeInfoPopup,
        [cashflowViewConsts.cashflowTypes.economy]: EconomyInfoPopup,
        savings: SavingsInfoPopup,
      },
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    trackInfoPopupClicked(cashflowType, additionalData) {
      Segment.trackUserInteraction('SectionMenu_MoreInfoClicked', {
        ...additionalData,
        cashflowType,
      });
    },
    shouldShowInfoPopups(isPreviousMonth, isBlocked, isOutOfDate) {
      return !isPreviousMonth && !isBlocked && !isOutOfDate;
    },
  },
};
</script>
