<template>
  <div>
    <span class="ri-font-weight-bold">הדרך ליציאה מהמינוס מתחילה בשמירה על תזרימים חיוביים לאורך זמן</span>,
    עכשיו כדאי לבחור את הצעדים הבאים שלך.
    <riseup-button class="margin-top-ml" title="לצעד הבא שלי" @click="$emit('click')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'NegativeCashflowsNegativeOsh',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
};
</script>

<style scoped lang="scss">

</style>
