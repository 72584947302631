<template>
  <card class="my-progress-total-accumulation-card ri-large-body">
    <div class="padding-vertical-m">{{ cardText }}
      <span v-if="!emptyState"
            class="ri-font-weight-bold pre-line">{{ !activationBeforeEarliestCashflow && positiveOsh ? '\nמאז צברת:': 'צברת סה"כ:' }}</span>
      <div class="flex-row space-between">
        <div class="sum-and-description margin-top-xs">
          <template v-if="!emptyState">
            <div class="ri-medium-display">
              <span class="ri-medium-headline">₪</span>{{ totalCashflowsAndSavingsSinceActivation | formatAmountWithFraction(0) }}
            </div>
            <div class="ri-small-body">תזרימים והפקדות לחיסכון בתזרים</div>
          </template>
          <action-button v-else class="margin-bottom-xs" :background-color="actionButtonBgColor" :text-color="actionButtonTextColor"
                         text="מה כדאי לעשות" no-icon @click="goToNextSteps"/>
        </div>
        <img class="illustration" :class="{ 'empty-state': emptyState }" :src="`/images/my-progress/${imagePath}.png`" alt="high-five"/>
      </div>
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import dateUtils from '@/utils/dates';
import _ from 'lodash';
import Segment from '@/Segment';

export default {
  name: 'MyProgressTotalAccumulationCard',
  components: {
    Card: BaseUI.Card,
    ActionButton: BaseUI.ActionButton,
  },
  data() {
    return {
      actionButtonBgColor: BaseUI.Colors.riseupBlue,
      actionButtonTextColor: BaseUI.Colors.riseupWhite,
    };
  },
  computed: {
    ...mapState('applicationState', ['activationDate']),
    ...mapState('customerProgress', ['customerProgress']),
    ...mapGetters('customerProgress', ['totalCashflowsAndSavingsSinceActivation']),
    ...mapGetters('balance', ['totalOshBalance']),
    positiveOsh() {
      return this.totalOshBalance >= 0;
    },
    activationBeforeEarliestCashflow() {
      return this.activationDate && moment(this.activationDate).isBefore(moment('2022-01-01'), 'month');
    },
    emptyState() {
      return _.isNil(this.totalCashflowsAndSavingsSinceActivation) || this.totalCashflowsAndSavingsSinceActivation < 1000;
    },
    cardText() {
      if (this.emptyState) {
        return 'כאן יופיע הסכום שצברת מאז שהצטרפת לרייזאפ. כרגע נראה שעדיין לא התחלת לצבור איתנו.';
      }
      const showActivationDate = this.activationDate && !this.activationBeforeEarliestCashflow;
      const sinceDateFormatted = dateUtils.getMonthAndYear(showActivationDate ? this.activationDate : '2022-01-01');
      const monthsDiff = moment().diff(moment(this.activationDate), 'months');
      if (this.positiveOsh) {
        return this.activationBeforeEarliestCashflow ? `מאז ${sinceDateFormatted} ` : `הצטרפת לרייזאפ לפני ${monthsDiff} חודשים,\n`;
      }
      return this.activationBeforeEarliestCashflow
        ? `אמנם העו״ש שלילי כרגע, אבל מאז ${sinceDateFormatted} `
        : `הצטרפת לרייזאפ לפני ${monthsDiff} חודשים, אמנם העו״ש שלילי כרגע אבל `;
    },
    imagePath() {
      return this.emptyState ? 'accumulation-empty-state-wink-coin' : 'accumulation-high-five-green-bg';
    },
    progressStatus() {
      return this.customerProgress?.progressState?.progressStatus;
    },
  },
  methods: {
    goToNextSteps() {
      Segment.trackUserInteraction('MyProgress_AccumulationCard_NextStepsClicked', { progressStatus: this.progressStatus });
      this.$router.push({ name: 'MyProgressNextSteps' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.my-progress-total-accumulation-card {
  background: $riseup_beige;
  .sum-and-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .pre-line {
    white-space: pre-line;
  }
  .illustration {
    height: 100px;
    &.empty-state {
      height: 90px;
    }
  }
}
</style>
