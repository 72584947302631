<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-summary-amount :is-preview-mode="true"/>
    <div class="info-popup-text">
        <span class="info-popup-title bold">
        איך המספר הזה מחושב?
        </span>
      <span>
            אחרי שהחסרנו מההכנסות שלך את כל ההוצאות שצפויות לרדת החודש, נשאר לנו סכום להוצאות היומיומיות - <span
        class="bold">המשתנות</span>.
        <span v-if="predictedBalanceAtEndOfMonth > 0">הסכום הזה מחולק לשבועות.</span>
        </span>
      <div class="margin-top">
        <span v-if="predictedBalanceAtEndOfMonth > 0">אם נעמוד ביעדים השבועיים נסיים את החודש בתזרים חיובי!</span>
        <span v-else>נראה שהחודש ההוצאות צפויות להיות גדולות מההכנסות, ואנחנו כאן ביחד איתך בזה. כדאי לעבור על התזרים ולוודא שהכל נכון.</span>
      </div>
    </div>
  </one-page-info-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import money from '@/utils/money';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'SummaryAmountInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseSummaryAmount: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSummaryAmount'),
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    formattedAmountLeft() {
      return money.formatAmountWithFraction(this.predictedBalanceAtEndOfMonth, 0);
    },
    cashflowType() {
      return 'summaryAmount';
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
