<template>
  <div class="ri-label">
    <div class="month-bars padding-horizontal-ml">
      <div class="previous-quarter flex-1">
        <div>
          <div class="amount">{{ lastQuarterAmount | formatAmountWithFraction(0) | shekel }}</div>
          <div class="amount-description">סה"כ</div>
        </div>
        <div class="bar" :class="{ zero: lastQuarterAmount === 0 }" :style="{ '--bar-height': previousBarHeight }"/>
      </div>
      <div class="current-quarter flex-1">
        <div class="flex-column align-center">
          <div class="amount">{{ currentQuarterAmount | formatAmountWithFraction(0) | shekel }}</div>
          <div class="amount-description">סה"כ</div>
        </div>
        <div class="bar" :class="{ zero: currentQuarterAmount === 0 }" :style="{ '--bar-height': currentBarHeight }"/>
      </div>
    </div>
    <separator/>
    <div class="month-names padding-horizontal-ml margin-top-s">
      <div>{{ previousQuarterMonths }}</div>
      <div>{{ currentQuarterMonths }}</div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import dateUtils from '@/utils/dates';
import { getCashflowMonthFromMonthsAgo, getCurrentCashflowMonth } from '@riseupil/common-utils';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'CategoryQuarterVsQuarterGraph',
  components: {
    separator: BaseUI.Separator,
  },
  data() {
    return {
      maxBarHeight: 88,
    };
  },
  props: {
    currentQuarterAmount: {
      type: Number,
      required: true,
    },
    lastQuarterAmount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    highestValue() {
      return _.max([this.currentQuarterAmount, this.lastQuarterAmount]);
    },
    currentBarHeight() {
      return `${(this.currentQuarterAmount / this.highestValue) * this.maxBarHeight}px`;
    },
    previousBarHeight() {
      return `${(this.lastQuarterAmount / this.highestValue) * this.maxBarHeight}px`;
    },
    lastMonth() {
      return getCashflowMonthFromMonthsAgo(getCurrentCashflowMonth(this.cashflowStartDay), 1);
    },
    currentMonthName() {
      return dateUtils.getShortHebrewMonth(this.lastMonth);
    },
    threeMonthsAgo() {
      return dateUtils.getShortHebrewMonth(getCashflowMonthFromMonthsAgo(this.lastMonth, 2));
    },
    fourMonthsAgo() {
      return dateUtils.getShortHebrewMonth(getCashflowMonthFromMonthsAgo(this.lastMonth, 3));
    },
    sixMonthsAgo() {
      return dateUtils.getShortHebrewMonth(getCashflowMonthFromMonthsAgo(this.lastMonth, 5));
    },
    currentQuarterMonths() {
      return `${this.threeMonthsAgo} - ${this.currentMonthName}`;
    },
    previousQuarterMonths() {
      return `${this.sixMonthsAgo} - ${this.fourMonthsAgo}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/border-radius";
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/typography";

$bar-width: 54px;
$bar-min-height: 8px;

.month-bars {
  display: flex;
  justify-content: space-around;
  column-gap: $ri-spacing-sm;
}

.current-quarter, .previous-quarter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  row-gap: $ri-spacing-s;
  line-height: $ri-font-size-16;
}

.amount-description {
  color: $riseup_gray_60;
}

.bar {
  min-height: $bar-min-height;
  width: $bar-width;
  height: var(--bar-height);
  background-color: var(--bar-color);
  border-top-right-radius: $ri-border-radius-small;
  border-top-left-radius: $ri-border-radius-small;
  &.zero {
    min-height: 0;
  }
}

.current-quarter {
  .bar {
    background: $riseup_blue;
  }
}

.previous-quarter {
  .bar {
    background: $riseup_light_blue;
  }
}

.month-names {
  display: flex;
  justify-content: space-around;
  color: $riseup_gray_60;
}
</style>
