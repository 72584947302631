<template>
  <popup class="trend-popup" :close-action="closePopup" :title="popupTitle">
    <template v-slot:content>
      <card class="trend-card" :has-content-padding="false">
        <bar-graph v-if="graphData" :with-sign='false' :graph-height="102" :bar-values="barValues"/>
      </card>
      <div v-if="isPresentingCurrentMonth">
        <div v-if="isPredictionHeuristic" class="prediction-text">הצפי לחודש {{ monthFullName }} {{ adjustToCashflowStart }} הוא: <span
          class="bold">{{ predictedAmount }} ₪</span>
          <div v-if="isWeeklyCategory">והוא מבוסס על שלושת החודשים האחרונים והוצאות שכבר יצאו החודש</div>
          <div v-else>והוא מבוסס על שלושת החודשים האחרונים</div>
        </div>
        <div v-else class="prediction-text">היעד שקבעת לחודש {{ monthFullName }} הוא: <span
          class="bold">{{ predictedAmount }} ₪</span></div>
      </div>
      <expandable-section header-text="למה הקטגוריה מופיעה אצלך?" class="expandable-container"
                          @expand-change="logExpandChanged">
        <template v-slot:content>
          <div class="category-reason">{{ categoryReason }}</div>
        </template>
      </expandable-section>
      <div v-if="isPresentingCurrentMonth">
        <riseup-button :action="editTrackingCategoryAmountPopup"
                       variant="primary"
                       :title="buttonText"
                       :size="'slim'"/>
      </div>
      <div v-else>
        <riseup-button :action="closePopup"
                       variant="primary"
                       title="הבנתי, תודה"
                       :size="'slim'"/>
      </div>
    </template>
    <template v-slot:footer v-if="isPresentingCurrentMonth">
      <a class="link-button" @click="closePopup">הבנתי, תודה</a>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import _ from 'lodash';
import Segment from '@/Segment';
import EditAmountPopup from '../current-month/components/cashflow-edit/EditAmountPopup';
import { getEnvelopeTrendValues } from './cashflow-trends';
import CashflowTrends from './CashflowTrendsMixin';

export default {
  name: 'TrackingCategoryCashflowTrend',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
    ExpandableSection: BaseUI.ExpandableSection,
    BarGraph: BaseUI.BarGraph,
  },
  mixins: [CashflowTrends],
  props: {
    categoryId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      trendType: 'Tracking Category',
    };
  },
  created() {
    this.trackCashflowTrendsOpened(this.trendType, { trackingCategory: this.category.categoryName });
  },
  computed: {
    ...mapGetters('cashflowView', ['trackingCategories', 'isPresentingCurrentMonth', 'currentWeek', 'monthFullName']),
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),

    graphData() {
      return getEnvelopeTrendValues(this.allCashflows, this.presentedBudgetDate, this.getTcTrendValuesForCashflow);
    },
    barValues() {
      return this.graphData ?? [];
    },
    category() {
      return this.trackingCategories.find(tc => tc.categoryId === this.categoryId);
    },
    predictedAmount() {
      return moneyUtils.formatAmountWithFraction(this.category.recommendedOrExpectedAmount, 0);
    },
    isPredictionHeuristic() {
      return this.category.categoryType === 'heuristic' && !this.category.isCustomPrediction;
    },
    isWeeklyCategory() {
      return this.category.frequency === 'weekly';
    },
    popupTitle() {
      return `הוצאות ${this.category.categoryName} לפי חודשים`;
    },
    categoryReason() {
      return this.isPredictionHeuristic ? 'קטגוריה מומלצת למעקב היא כזו שהמערכת זיהתה שיש לך בה הוצאות כל חודש. '
        + 'הצפי החודשי נקבע לפי ממוצע החודשים הקודמים, אפשר גם לקבוע אותו בעצמך. '
        : 'זאת קטגוריה שבחרת לעקוב אחריה וקבעת לה את היעד. אפשר להעביר אליה הוצאות שמתאימות לה מקטגוריות אחרות.';
    },
    buttonText() {
      return this.isPredictionHeuristic ? 'לעריכת הצפי' : 'לעריכת היעד';
    },
    adjustToCashflowStart() {
      if (this.cashflowStartDay === 1) {
        return '';
      }

      return `(${dateUtils.getMonthTimeFrame(this.presentedBudgetDate, this.cashflowStartDay)}) `;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    getTcTrendValuesForCashflow(cf, index) {
      let value;
      let secondaryValue;
      if (this.category.frequency === 'monthly') {
        const monthlyEnvelope = cf.envelopes.find(e => e.details.trackingCategory?._id === this.category.categoryId);
        if (!monthlyEnvelope) {
          return null;
        }
        value = monthlyEnvelope.actualsAmount;
        secondaryValue = index === 0 && !monthlyEnvelope.actualsExceedPrediction ? monthlyEnvelope.predictedAmount : undefined;
      } else { // weekly
        const weeklyEnvelopes = cf.envelopes.filter(e => e.details.trackingCategory?._id === this.category.categoryId);
        if (!weeklyEnvelopes?.length) {
          return null;
        }
        const actuals = _.sumBy(weeklyEnvelopes, e => e.actualsAmount);
        const actualsExceedPrediction = actuals >= this.category.recommendedOrExpectedAmount;
        value = actuals;
        secondaryValue = index === 0 && !actualsExceedPrediction ? this.category.recommendedOrExpectedAmount : undefined;
      }
      return {
        value,
        secondaryValue: this.isPresentingCurrentMonth ? secondaryValue : undefined,
        color: index === 0 ? BaseUI.Colors.riseupBlue : BaseUI.Colors.riseupLightBlue,
        focused: index === 0,
      };
    },
    closePopup() {
      this.closeCashflowTrendPopup(this.trendType);
    },
    logExpandChanged(newValue) {
      this.logCashflowTrendExpandChanged(this.trendType, newValue);
    },
    editTrackingCategoryAmountPopup() {
      Segment.trackUserInteraction('CashflowTrends_EditAmount', {
        type: this.trendType,
        isPredictionHeuristic: this.isPredictionHeuristic,
      });
      this.openModal({
        component: EditAmountPopup,
        props: {
          expenseCategory: this.category.categoryName,
          category: this.category.categories.length > 1 ? this.category.categories[this.currentWeek.weekIndex] : this.category.categories[0],
        },
        eventHandlers: {
          success: this.closePopup,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-trends.styles";
</style>
