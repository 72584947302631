<template>
  <card v-if="topBusinesses.length > 0" :has-content-padding="false">
    <div class="padding-horizontal-ml padding-top-ml">
      <div class="ri-bold-title margin-bottom-xs">בתי עסק שהוצאת בהם הכי הרבה</div>
      <div v-for="(business, index) in topThreeBusinesses" :key="business.businessName"
           @click="searchBusinessName(business)" @keyup.enter="searchBusinessName(business)" tabindex="0">
        <div class="flex-row align-center space-between padding-vertical-sm pointer">
          <numbered-bullet :number="index + 1" class="bullet margin-left-xs"/>
          <div class="ri-label flex-1 ellipsis-text">{{ business.businessName }}</div>
          <div class="flex-row align-center">
            <div class="ri-bold-label margin-left-xs">
              {{ business.transactionsSum | formatAmountWithFraction(0) | shekel }}
            </div>
            <icon icon-name="arrow_back_ios_new" size="12px" weight="bold" :optical-size="24"/>
          </div>
        </div>
        <separator v-if="index !== topThreeBusinesses.length - 1"/>
      </div>
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';

export default {
  name: 'CategoryTrendBusinessesCard',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
    Separator: BaseUI.Separator,
    NumberedBullet: BaseUI.NumberedBullet,
  },
  props: {
    topBusinesses: {
      type: Array,
      required: true,
    },
  },
  computed: {
    topThreeBusinesses() {
      return _.orderBy(this.topBusinesses, 'transactionsSum', 'desc').slice(0, 5);
    },
  },
  methods: {
    searchBusinessName({ businessName }) {
      this.$router.push({ name: 'Home', query: { q: businessName } });
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}

.numbered-bullet.bullet .circle {
  font-size: 12px;
  font-weight: 400;
  width: 21px;
  height: 21px;
  line-height: unset;
}
</style>
