<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-excluded :is-preview-mode="true"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">הוצאות והכנסות מחוץ לתזרים</span>
      <span>
        אם העברת <span class="bold">הכנסה</span> לכאן, כנראה שהיא לא נועדה לממן את ההוצאות של החודש הזה. למשל: הלוואה שלקחנו.
      </span>
      <span class="margin-top">
        אם העברת <span class="bold">הוצאה</span> לכאן, התכוונת שהיא לא ממומנת מההכנסות של החודש הזה. למשל: קניית רכב.
      </span>
    </div>
  </one-page-info-popup>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'ExcludedInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseExcluded: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseExcluded'),
  },
  computed: {
    cashflowType() {
      return cashflowViewConsts.cashflowTypes.variableExpense;
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
