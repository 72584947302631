<template>
  <popup class="trend-popup fixed-expense" :close-action="closePopup" :title="popupTitle" remove-content-padding>
    <template v-slot:content>
      <slider :slides-data="slidesData" @on-slide="logSwipe" :max-dots="maxDots" :first-slide-index="firstSlideIndex">
        <template v-slot:default="{ slideData }">
          <card class="trend-card" :has-content-padding="false">
            <div class="trend-card-content">
              <div class="card-title">{{ slideData.key }}</div>
              <bar-graph v-if="graphData[slideData.key]" :with-sign='false' :graph-height="102"
                         :bar-values="barValues[slideData.key]"/>
            </div>
          </card>
        </template>
      </slider>
      <div class="vertical-padding">

        <div v-if="isPresentingCurrentMonth">
          <div class="prediction-text">
            <span>הצפי לכל ההוצאות הקבועות ב{{ monthFullName }} {{ adjustToCashflowStart }} הוא:</span>
            <span class="bold">{{ predictedAmount }} ₪</span>
            <div>והוא מבוסס על חודשים קודמים</div>
          </div>
        </div>
        <expandable-section header-text="איך זה מחושב?" class="expandable-container" @expand-change="logExpandChanged">
          <template v-slot:content>
            <div class="category-reason">
              המערכת מזהה את ההוצאות שירדו וגם את אלו שצפויות לרדת עד סוף החודש, לפי החודשים הקודמים.
            </div>
          </template>
        </expandable-section>
        <div>
          <riseup-button :action="closePopup"
                         variant="primary"
                         title="הבנתי, תודה"
                         :size="'slim'"/>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapState } from 'vuex';
import moneyUtils from '@/utils/money';
import cashflowViewConsts from '@/constants/cashflow-view';
import dateUtils from '@/utils/dates';
import { getAllFixedExpenseCategories, getEnvelopeTrendValues } from './cashflow-trends';
import CashflowTrends from './CashflowTrendsMixin';

export default {
  name: 'FixedExpenseCashflowTrend',
  components: {
    BarGraph: BaseUI.BarGraph,
    Popup: BaseUI.Popup,
    Card: BaseUI.Card,
    ExpandableSection: BaseUI.ExpandableSection,
    RiseupButton: BaseUI.RiseupButton,
    Slider: BaseUI.Slider,
  },
  mixins: [CashflowTrends],
  data() {
    return {
      trendType: 'Fixed Expense',
      allCategoriesTrendTitle: 'כל ההוצאות הקבועות',
      firstSlideIndex: 0,
    };
  },
  props: {
    deepLinkToPage: {
      type: String,
      required: false,
    },
  },
  created() {
    this.getTrendValues();
    if (this.deepLinkToPage) {
      const indexOfPage = this.expenseCategories.indexOf(this.deepLinkToPage);
      this.firstSlideIndex = indexOfPage > -1 ? indexOfPage : 0;
    }
    this.trackCashflowTrendsOpened(this.trendType, { expenseCategoriesCount: this.slidesData.length });
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth', 'monthFullName']),
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),
    graphData() {
      return this.getTrendValues();
    },
    barValues() {
      return this.graphData ?? {};
    },
    category() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.fixedExpense];
    },
    predictedAmount() {
      return moneyUtils.formatAmountWithFraction(this.category.recommendedOrExpectedAmount, 0);
    },
    expenseCategories() {
      return [this.allCategoriesTrendTitle, ...getAllFixedExpenseCategories(this.allCashflows, this.presentedBudgetDate)];
    },
    slidesData() {
      return this.expenseCategories.map(category => {
        return { key: category };
      });
    },
    popupTitle() {
      return 'הוצאות קבועות לפי חודשים';
    },
    maxDots() {
      return this.slidesData.length > 5 ? 5 : undefined;
    },
    adjustToCashflowStart() {
      if (this.cashflowStartDay === 1) {
        return '';
      }
      return `(${dateUtils.getMonthTimeFrame(this.presentedBudgetDate, this.cashflowStartDay)}) `;
    },
  },
  methods: {
    getTrendValues() {
      const data = this.expenseCategories.reduce((acc, curr) => {
        // create a trend graph for each category
        acc[curr] = getEnvelopeTrendValues(
          this.allCashflows,
          this.presentedBudgetDate,
          (cf, index) => this.getFixedExpenseCategoryTrendValuesForCashflow(cf, index, curr),
        );
        return acc;
      }, {});

      // create a summary trend graph for all categories
      data[this.allCategoriesTrendTitle] = getEnvelopeTrendValues(
        this.allCashflows,
        this.presentedBudgetDate,
        this.getTotalFixedExpenseTrendValuesForCashflow,
      );
      this.sortExpenseCategoriesByBalanceThisMonth(data);
      return data;
    },
    /**
     * Get the trend values for a specific fixed expense category (bills, checks, etc.)
     */
    getFixedExpenseCategoryTrendValuesForCashflow(cf, index, expenseCategory) {
      const category = cf.fixedTree.expenseCategories[expenseCategory];
      return {
        value: category ? category.actualsAmount : 0,
        secondaryValue: (category && index === 0 && this.isPresentingCurrentMonth) ? category.balancedAmount : undefined,
        color: index === 0 ? BaseUI.Colors.riseupRed : BaseUI.Colors.riseupLightRed,
        focused: index === 0,
      };
    },
    /**
     * Get the trend values which summarize all fixed expense categories
     */
    getTotalFixedExpenseTrendValuesForCashflow(cf, index) {
      return {
        value: cf.fixedTree.fixedExpenseEnvelopesActualSum,
        secondaryValue: (index === 0 && this.isPresentingCurrentMonth) ? cf.fixedTree.fixedExpenseEnvelopesBalanceSum : undefined,
        color: index === 0 ? BaseUI.Colors.riseupRed : BaseUI.Colors.riseupLightRed,
        focused: index === 0,
      };
    },
    sortExpenseCategoriesByBalanceThisMonth(data) {
      this.expenseCategories.sort((a, b) => {
        const categoryB = data[b];
        const categoryA = data[a];
        const aValue = Math.max(categoryA[0].secondaryValue ?? 0, categoryA[0].value);
        const bValue = Math.max(categoryB[0].secondaryValue ?? 0, categoryB[0].value);
        return bValue - aValue;
      });
    },
    closePopup() {
      this.closeCashflowTrendPopup(this.trendType);
    },
    logExpandChanged(newValue) {
      this.logCashflowTrendExpandChanged(this.trendType, newValue);
    },
    logSwipe(newIndex) {
      this.logCashflowTrendsSwipe(this.trendType, this.expenseCategories[newIndex], newIndex + 1, this.slidesData.length);
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-trends.styles";

</style>
