<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-income :is-preview-mode="true"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">הכנסות</span>
      <span>אפשר לראות כמה כבר נכנס וכמה עוד צפוי להכנס עד סוף החודש. לפי זה נחשב כמה כסף נשאר להוציא.</span>
    </div>
  </one-page-info-popup>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'IncomeInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseIncome: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseIncome'),
  },
  computed: {
    cashflowType() {
      return cashflowViewConsts.cashflowTypes.income;
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
