<template>
  <div class="expandable-cashflow-transactions">
    <expandable-summary-row :arrow-size="arrowSize" :is-expandable="anyTransactions" :header-text="summaryRowText" :out-of-date="outOfDate"
                            :card-type-id="cardTypeId" :initially-expanded="initiallyExpanded" :type="categoryType" :has-update="showUpdateIndicator"
                            @expanded="expanded" :items-keys="itemsKeys">
      <template v-slot:header v-if="isSubCategory">
        <sub-category-summary-header :sub-category="category"
                                     :can-edit-category="!outOfDate && !previousMonth && !!category.isEditable && !disableEditing"
                                     :show-recommended-amount="!previousMonth"
                                     :cashflow-category="cashflowCategory"
                                     :out-of-date="outOfDate"/>
      </template>
      <template v-slot:content>
       <cashflow-type-transactions :category="category"
                                   :card-type-id="cardTypeId"
                                   :use-category-id="useCategoryId"
                                   :category-name="categoryName"
                                   :category-type="categoryType"
                                   :out-of-date="outOfDate"
                                   :disable-editing="disableEditing"
                                   :is-predicted-transaction="isPredictedTransaction"/>
      </template>
    </expandable-summary-row>
  </div>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import ExpandableSummaryRow from './ExpandableSummaryRow';
import CashflowTypeTransactions from './CashflowTypeTransactions';
import SubCategorySummaryHeader from './SubCategorySummaryHeader';

export default {
  name: 'ExpandableCashflowTransactions',
  components: {
    ExpandableSummaryRow,
    CashflowTypeTransactions,
    SubCategorySummaryHeader,
  },
  data() {
    return {
      didExpand: false,
    };
  },
  mounted() {
    if (this.initiallyExpanded) {
      this.didExpand = true;
    }
  },
  props: {
    timeFrame: {
      type: String,
      default: 'monthly',
      validator: val => ['monthly', 'weekly'].includes(val),
    },
    cardTypeId: {
      type: String,
      required: true,
    },
    isSubCategory: {
      type: Boolean,
      default: false,
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    summaryRowTitle: {
      type: String,
      required: false,
    },
    useCategoryId: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      required: true,
    },
    arrowSize: {
      type: String,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    categoryName: {
      type: String,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    isPredictedTransaction: {
      type: Boolean,
      default: false,
    },
    categoryType: {
      type: String,
      required: false,
    },
  },
  computed: {
    transactions() {
      return this.category.transactions;
    },
    anyTransactions() {
      return this.transactions.length > 0;
    },
    showUpdateIndicator() {
      return !this.didExpand && this.category.hasUpdate;
    },
    canEditCategory() {
      return !!this.category.isEditable && !this.outOfDate;
    },
    categoryId() {
      return this.category.categoryId;
    },
    weeklyOrMonthly() {
      if (this.timeFrame === 'monthly') {
        return 'חודשי';
      }
      return 'שבועי';
    },
    summaryRowText() {
      if (this.isSubCategory) {
        return undefined;
      }
      return this.summaryRowTitle ? this.summaryRowTitle : `פירוט ${this.weeklyOrMonthly}`;
    },
    itemsKeys() {
      return this.category.transactions.map(transaction => {
        if (this.isPredictedTransaction) {
          return transaction.actual ? transaction.actual.transactionId : transaction.sequenceId;
        }
        return transaction.transactionId;
      });
    },
  },
  methods: {
    expanded(hasUpdate) {
      if (!this.isSubCategory || hasUpdate) {
        EventBus.$emit(`cashflow-type-card-expanded-${this.cardTypeId}`);
      }
      this.didExpand = true;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
