<template>
  <card :has-content-padding="false">
    <div class="padding-ml">
      <div class="ri-bold-title margin-bottom-sm">"{{categoryName}}" לאורך תקופה</div>
      <action-button class="filter" :text="filter.shortLabel" :override-icon-props="{ iconName: 'calendar_today', fill: true }"
                     background-color="transparent" :show-left-arrow="false" @click="openDateFilter" />
      <div class="margin-top-l flex-row">
        <div class="flex-column">
          <div class="ri-title">{{ average | formatAmountWithFraction(0) | shekel }}</div>
          <div class="ri-label">ממוצע חודשי</div>
        </div>
      </div>
      <bar-graph-v2 class="margin-top-xxs" :raw-values="rangedDataset" :value-formatter="valueFormatter" :label-formatter="labelFormatter"
                    :x-tick-formatter="xTickFormatter" :y-tick-formatter="yTickFormatter" :bar-styles="barStyles"/>
      <div class="full-width padding-top-ml padding-horizontal-xxs">
        <img class="full-width" src="/images/past-editable-month/arrow-left.svg">
      </div>
      <div v-if="activationDateVisible" class="activation-indicator ri-small-body">
        <img src="/images/shapes/six-pointed-star.svg" />
        הצטרפת לרייזאפ
      </div>
      <div class="touch-info ri-small-body">
        <icon icon-name="info" />
        פירוט הסכום בכל חודש מופיע בנגיעה בעמודה הרלוונטית
      </div>
      <separator class="margin-top-ml" />
      <div class="ri-body margin-top-ml">
        בין החודשים {{ filter.start | getMonthAndShortYear }} - {{ filter.end | getMonthAndShortYear }} הוצאת בקטגוריה סה"כ:
      </div>
      <div class="ri-title margin-top-s">{{ sum | formatAmountWithFraction(0) | shekel }}</div>
    </div>
  </card>
</template>

<script>
import moment from 'moment';
import BaseUI from '@riseupil/base-ui';
import BarGraphV2 from '@/base-components/bar-graph-v2/BarGraphV2';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import { getCurrentCashflowMonth, getCashflowMonthFromMonthsAgo } from '@riseupil/common-utils';
import MyProgressDateFilter
  from '@/pages/responsive-pages/authenticated/navigation-pages/my-progress/MyProgressDateFilter';
import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import { DATE_FILTERS_STRINGS } from '../../my-progress-filters.consts';

export default {
  name: 'CategoryTrendGraphCard',
  components: {
    BarGraphV2,
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
    ActionButton: BaseUI.ActionButton,
    Separator: BaseUI.Separator,
  },
  data() {
    return {
      barStyles: {
        width: '12px',
        borderRadius: '10px',
      },
      filter: {
        filterKey: 'last-6-months',
        ...DATE_FILTERS_STRINGS['last-6-months'],
        start: null,
        end: null,
      },
    };
  },
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    graphData: {
      type: Array, // { cashflowMonth: string, amount: numer}[]
      required: true,
    },
  },
  created() {
    const currentCashflowMonth = getCurrentCashflowMonth();
    this.filter.end = getCashflowMonthFromMonthsAgo(currentCashflowMonth, 1);
    this.filter.start = getCashflowMonthFromMonthsAgo(currentCashflowMonth, 6);
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    ...mapState('applicationState', ['activationDate']),
    oldestCfMonth() {
      return _.minBy(this.graphData, ({ cashflowMonth }) => cashflowMonth).cashflowMonth;
    },
    rangedDataset() {
      const monthsInRange = moment(this.filter.end).diff(this.filter.start, 'months') + 1;
      const cashflowMonths = _.times(monthsInRange, i => dateUtils.formatCashflowDate(moment(this.filter.start).add(i, 'months')));
      return cashflowMonths.map(month => this.graphData.find(({ cashflowMonth }) => cashflowMonth === month) || { cashflowMonth: month });
    },
    monthsWithData() {
      return this.rangedDataset.filter(cf => !_.isNil(cf.amount));
    },
    average() {
      return _.meanBy(this.monthsWithData, 'amount');
    },
    sum() {
      return _.sumBy(this.monthsWithData, 'amount');
    },
    activationDateVisible() {
      return this.activationDate && moment(this.activationDate).isBetween(this.filter.start, this.filter.end, 'month', '[]');
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openDateFilter() {
      Segment.trackUserInteraction('MyProgress_CategoryTrendGraphCard_DateFilter_Clicked');
      this.openModal({
        component: MyProgressDateFilter,
        props: {
          ...this.filter,
          oldestCfMonth: this.oldestCfMonth,
        },
        popupAlignment: 'menu',
        eventHandlers: {
          'filters-changed': newDates => {
            this.filter = newDates;
          },
        },
      });
    },
    valueFormatter({ amount }) {
      return { value: amount };
    },
    labelFormatter(amount) {
      return `${moneyUtils.formatAmountWithFraction(amount, 0)} ₪`;
    },
    xTickFormatter({ cashflowMonth }) {
      return {
        value: dateUtils.getShortHebrewMonth(cashflowMonth),
        mark: moment(cashflowMonth).isSame(this.activationDate, 'month'),
      };
    },
    yTickFormatter(value) {
      if (value < 1000) {
        return `${moneyUtils.formatAmountWithFraction(value, 0)} ₪`;
      }
      const rounded = Math.ceil(Math.abs(value) / 1000);
      return `${rounded > 1 ? rounded : ''}${Math.sign(value) === -1 ? '-' : ''} אלף`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";
.activation-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  margin-top: $ri-spacing-xs;
  margin-bottom: $ri-spacing-xs;
}
.touch-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 4px;
  padding-top: $ri-spacing-xs;
}
</style>
