<template>
  <cashflow-type-card
      :is-preview-mode="isPreviewMode"
      :card-type-id="CASHFLOW_TYPE"
      :can-edit-category="false"
      :card-title="'הוצאות קבועות'"
      :sub-tree="fixedExpenses"
      :cashflow-category="cashflowCategory"
      :blocked="blocked"
      :previous-month="previousMonth"
      :out-of-date="outOfDate">
    <template v-slot:bottom>
      <expandable-category-details :cashflow-category="cashflowCategory"
                                   :card-type-id="CASHFLOW_TYPE"
                                   :category-name="CASHFLOW_TYPE"
                                   :category-type="CASHFLOW_TYPE"
                                   :is-predicted-transaction="true"
                                   :blocked="blocked"
                                   :categories="fixedExpenses.categories"
                                   :previous-month="previousMonth"
                                   :has-update="fixedExpenses.hasUpdate"
                                   :disable-editing="isPreviewMode"
                                   :out-of-date="outOfDate"/>
    </template>
  </cashflow-type-card>
</template>

<script>

import { mapState } from 'vuex';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';
import CashflowTypeCard from './CashflowTypeCard';
import ExpandableCategoryDetails from './ExpandableCategoryDetails';

export default {
  name: 'CashflowTypeBaseFixedExpense',
  components: {
    CashflowTypeCard,
    ExpandableCategoryDetails,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    CASHFLOW_TYPE() {
      return cashflowViewConsts.cashflowTypes.fixedExpense;
    },
    fixedExpenses() {
      return this.cashflowUITree[this.CASHFLOW_TYPE];
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED;
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-type-base.styles";
</style>
