<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-fixed-expense :is-preview-mode="true"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">הוצאות שבטוח ירדו החודש</span>
      <span>המערכת מזהה את ההוצאות שירדו וגם את אלו שצפויות לרדת עד סוף החודש, לפי  החודשים הקודמים.</span>
    </div>
  </one-page-info-popup>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'FixedExpenseInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseFixedExpense: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseFixedExpense'),
  },
  computed: {
    cashflowType() {
      return cashflowViewConsts.cashflowTypes.fixedExpense;
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
