<template>
  <one-page-info-popup :cashflow-type="cashflowType">
    <cashflow-type-base-variable-expense :is-preview-mode="true" time-frame="weekly" :initiallyExpanded="false"/>
    <div class="info-popup-text">
      <span class="info-popup-title bold">איך זה מחושב?</span>
      <span>
        אחרי שהחסרנו מההכנסות שלך את כל ההוצאות שצפויות לרדת החודש, נשאר לנו סכום להוצאות היומיומיות -
        <span class="bold">המשתנות</span>.
        הסכום הזה מחולק לשבועות.
          </span>
      <span v-if="predictedBalanceAtEndOfMonth <= 0">נראה שהחודש ההוצאות צפויות להיות גדולות מההכנסות, כדאי לעבור על התזרים ולוודא שהכל נכון.</span>
    </div>
  </one-page-info-popup>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import { mapGetters } from 'vuex';
import money from '@/utils/money';
import OnePageInfoPopup from './OnePageInfoPopup';

export default {
  name: 'VariablesInfoPopup',
  components: {
    OnePageInfoPopup,
    CashflowTypeBaseVariableExpense: () => import('../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseVariableExpense'),
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    cashflowType() {
      return cashflowViewConsts.cashflowTypes.variableExpense;
    },
    formattedAmountLeft() {
      return money.formatAmountWithFraction(this.predictedBalanceAtEndOfMonth, 0);
    },
  },
};
</script>

<style lang="scss">
@import "info-popups";
</style>
