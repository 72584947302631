<template>
  <div class="categories-table-header" :class="{ 'out-of-date': outOfDate }">
    <div class="actuals-title">{{ actualInOrOut }}</div>
    <div v-if="showRecommendedAmount" class="recommended-title">{{ recommendedTitle ? recommendedTitle : recommendedInOrOut }}</div>
  </div>
</template>

<script>

export default {
  name: 'CategoriesTableHeader',
  components: {
  },
  props: {
    isIncome: {
      type: Boolean,
      default: false,
    },
    showRecommendedAmount: {
      type: Boolean,
      required: true,
    },
    recommendedTitle: {
      type: String,
      required: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actualInOrOut() {
      if (this.isIncome) {
        return 'נכנס';
      }
      return 'יצא';
    },
    recommendedInOrOut() {
      if (this.isIncome) {
        return ' צפוי להיכנס';
      }
      return 'צפוי לצאת';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import '../../../../../../../../../scss/mixins';

.categories-table-header {
  color: $riseup_black;
  display: grid;
  padding: 15px 24px 10px 24px;
  @include cashflow-grid-columns;
  font-size: 14px;
  &.out-of-date {
    color: $riseup_gray_2;
  }

  .actuals-title {
    font-weight: bold;
    grid-column: 2/3;
    white-space: nowrap;
  }

  .recommended-title {
    font-weight: bold;
    grid-column: 3/4;
  }
}

</style>
