<template>
  <div class="predicted-variable-income-info-popup">
    <popup :buttons="buttons" :close-action="closeAction">
      <template v-slot:title>
        <div class="popup-title">מתי כדאי לעדכן צפי הכנסות משתנות?</div>
      </template>
      <template v-slot:content>
        <div class="description">
          <div class="text">
            אם ידוע לך על
            <b> הכנסה חד־פעמית שצפויה להיכנס לחשבון הבנק החודש{{ cashflowStartDay === 1 ? ',' : '' }}</b>
            {{ cashflowStartDay !== 1 ? `(עד ה־${adjustedMonthText}),` : '' }}
            אפשר לעדכן את הצפי בהתאם.
            <br/><br/>
            בסוף החודש, התזרים יתעדכן לפי ההכנסות שהגיעו בפועל.
          </div>
          <callout iconPath="icons/info-icon-blue.svg"
                   class="tooltip" title="כדאי לשים לב">
            <template v-slot:text>
              אם רצית לעדכן סכום של משכורת - או כל הכנסה קבועה אחרת - צריך לעדכן אותה בנפרד.
            </template>
          </callout>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import dateUtils from '@/utils/dates';
import predictedVariableIncomeConsts from './consts';
import EditPredictedVariableIncomePopup from './EditPredictedVariableIncomePopup';

export default {
  name: 'PredictedVariableIncomeInfoPopup',
  components: {
    Popup: BaseUI.Popup,
    Callout: BaseUI.Callout,
  },
  created() {
    Segment.trackUserGot('PredictedVariableIncomeInfoPopup_Opened');
    localStorage.setItem(predictedVariableIncomeConsts.INFO_POPUP_DISPLAYED_KEY, true);
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay', 'cashflow']),
    buttons() {
      return [
        {
          title: 'הבנתי, אפשר להמשיך',
          action: this.nextPopup,
          size: 'slim',
        },
      ];
    },
    adjustedMonthText() {
      return dateUtils.endOfMonthText(this.cashflow.budgetDate, this.cashflowStartDay);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    nextPopup() {
      Segment.trackUserInteraction('PredictedVariableIncomeInfoPopup_NextPopup_Clicked');
      this.closeModal();
      this.openModal({ component: EditPredictedVariableIncomePopup });
    },
    closeAction() {
      Segment.trackUserInteraction('PredictedVariableIncomeInfoPopup_Closed');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>

.predicted-variable-income-info-popup {
  font-size: 18px;

  .popup-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .description {
    line-height: 24px;
    text-align: right;

    .text {
      margin-bottom: 28px;
    }

    .tooltip {
      margin-bottom: 8px;
    }
  }
}
</style>
