<template>
  <card class="overflow-hidden" :has-content-padding="false">
    <div class="flex-column full-height">
      <div class="trend-data flex-1 flex-column space-between padding-vertical-sm">
        <div class="flex-column">
          <div class="ri-large-bold-body padding-horizontal-sm">{{ truncatedCategoryName }}</div>
          <img v-if="!isPositive" class="negative-trend-badge" :class="{ hidden: hideBadge }"
               src="/images/my-progress/negative-trend-great-badge.svg"/>
          <img v-else class="negative-trend-badge" :class="{ hidden: hideBadge }"
               src="/images/my-progress/positive-trend-heart-badge.svg"/>
        </div>
        <div class="flex-column padding-horizontal-sm">
          <div class="trend-percent flex-row">
            <div class="ri-medium-display">
              <span v-if="changePercent < 9.99">{{ changePercent * 100 | formatAmountWithFraction(0) }}%</span>
              <div v-else><div class="ri-label">מעל</div>999%</div>
            </div>
            <icon class="margin-bottom-xs" :icon-name="changeIconName" size="18px" :color="changeColor" weight="bold"/>
          </div>
          <div>{{ changeText }}</div>
        </div>
      </div>
      <div class="flex-column">
        <separator/>
        <a class="expand-footer ri-bold-label" @click="goToCategoryProgressPage" @keyup.enter="goToCategoryProgressPage" tabindex="0">
          לפירוט המלא
          <Icon icon-name="arrow_back_ios_new" fill size="12px" weight="bold"/>
        </a>
      </div>
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'CategoryTrendsSummaryCard',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
    Separator: BaseUI.Separator,
  },
  data() {
    return {
      negativeChangeColor: BaseUI.Colors.riseupGreen,
      positiveChangeColor: BaseUI.Colors.riseupDangerRed,
    };
  },
  props: {
    categoryIdHash: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    changePercent: {
      type: Number,
      required: true,
    },
    isPositive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    changeText() {
      return this.isPositive ? 'עלייה בהוצאות' : 'צימצום בהוצאות';
    },
    changeColor() {
      return this.isPositive ? this.positiveChangeColor : this.negativeChangeColor;
    },
    changeIconName() {
      return this.isPositive ? 'north' : 'south';
    },
    truncatedCategoryName() {
      return this.categoryName.length > 16 ? `${this.categoryName.substring(0, 16)
        .trim()}...` : this.categoryName;
    },
    hideBadge() {
      return this.categoryName === 'תרומה';
    },
  },
  methods: {
    goToCategoryProgressPage() {
      this.$router.push({ path: `/my-progress/category/${this.categoryIdHash}` });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/typography";
@import "~@riseupil/base-ui/src/scss/layout";

.overflow-hidden {
  overflow: hidden;
}

.trend-data {
  .negative-trend-badge {
    width: 45%;
    max-width: 88px;
    align-self: flex-end;
    margin-bottom: $ri-spacing-xs;
    margin-left: $ri-spacing-s;
    &.hidden {
      visibility: hidden;
    }
  }

  .trend-percent {
    align-items: flex-end;
  }
}

.expand-footer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: $ri-spacing-s $ri-spacing-sm;
}
</style>
