<template>
  <card class="my-progress-card" :has-content-padding="false">
    <div class="flex-row full-width space-between padding-horizontal-sm">
      <div class="flex-column space-between padding-vertical-m margin-horizontal-xs">
        <div class="ri-large-bold-body">{{ cardType }}</div>
        <div class="ri-label">
          <div class="monthly-average">
            <div class="ri-medium-display">{{ monthlyAverage | signedFormatAmountWithFraction(0) }}</div>
            <span class="ri-medium-headline">₪</span>
          </div>
          ממוצע חודשי
        </div>
      </div>
      <slot name="asset"/>
    </div>
    <template v-slot:footer>
      <a class="expand-footer ri-bold-label" @click="$emit('click')" @keyup.enter="$emit('click')" tabindex="0">
        <div class="flex-row align-center">
          <Icon class="margin-left-xxs" icon-name="bar_chart" fill size="16px" weight="bold"/>
          לפירוט המלא
        </div>
        <Icon icon-name="arrow_back_ios_new" fill size="12px" weight="bold"/>
      </a>
    </template>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'MyProgressCard',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
  },
  props: {
    cardType: {
      type: String,
      required: true,
    },
    monthlyAverage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.expand-footer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: $ri-spacing-sm 0;
}

.my-progress-card {
  background-color: $riseup_white;
  margin-bottom: $ri-spacing-m;
  .monthly-average {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

</style>
