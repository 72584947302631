<template>
  <cashflow-type-card
    :is-preview-mode="isPreviewMode"
    :card-type-id="cardId"
    :can-edit-category="cabEditCategory"
    :card-title="subtree.categoryName"
    :sub-tree="subtree"
    :cashflow-category="cashflowCategory"
    :blocked="blocked"
    :previous-month="previousMonth"
    :show-warning-if-exceeding-budget="!previousMonth"
    :out-of-date="outOfDate">
    <template v-slot:bottom>
      <expandable-category-details v-if="subtree.frequency === 'weekly'"
                                   :card-type-id="cardId"
                                   :cashflow-category="cashflowCategory"
                                   :category-name="TRACKING_CATEGORY"
                                   :category-type="TRACKING_CATEGORY"
                                   :time-frame="'weekly'"
                                   :blocked="blocked"
                                   :use-category-id="true"
                                   :categories="subtree.categories"
                                   :previous-month="previousMonth"
                                   :has-update="subtree.hasUpdate"
                                   :disable-editing="isPreviewMode"
                                   :out-of-date="outOfDate"/>
      <expandable-cashflow-transactions v-else-if="subtree.categories[0].transactions.length > 0"
                                        :card-type-id="cardId"
                                        :category="subtree.categories[0]"
                                        :category-name="TRACKING_CATEGORY"
                                        :category-type="TRACKING_CATEGORY"
                                        :use-category-id="true"
                                        :out-of-date="outOfDate"
                                        :disable-editing="isPreviewMode"
                                        :arrow-size="'medium'"/>
    </template>
  </cashflow-type-card>
</template>

<script>
import CashflowTypeCard from './CashflowTypeCard';
import ExpandableCashflowTransactions from './ExpandableCashflowTransactions';
import ExpandableCategoryDetails from './ExpandableCategoryDetails';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';

export default {
  name: 'CashflowTypeBaseTrackingCategory',
  components: {
    CashflowTypeCard,
    ExpandableCashflowTransactions,
    ExpandableCategoryDetails,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    subtree: {
      type: Object,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    CASHFLOW_TYPE() {
      return this.subtree.categoryName;
    },
    TRACKING_CATEGORY() {
      return cashflowViewConsts.cashflowTypes.trackingCategory;
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING;
    },
    cabEditCategory() {
      return this.subtree.frequency === 'monthly';
    },
    cardId() {
      return this.CASHFLOW_TYPE === 'סופר' ? cashflowViewConsts.cashflowTypes.economy : this.CASHFLOW_TYPE;
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-type-base.styles";
</style>
