<template>
  <div class="info-row">
    <colored-svg class="flower" :color="flowerColor" svg-src="/images/shapes/rounded-eight-points-star.svg" />
    <span class="ri-body">{{text}}</span>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'OfferingDetailsBullet',
  components: {
    coloredSvg: BaseUI.ColoredSvg,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      flowerColor: BaseUI.Colors.riseupGreen,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';
.info-row {
  display: flex;
  width: 100%;
  color: $riseup_gray_100;
  gap: $ri-spacing-s;

  .flower {
    width: 20px;
    height: 20px;
  }

}
</style>
