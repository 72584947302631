<template>
  <div class="summary-amount-card-wrapper">
    <card class="summary-amount-card" :has-footer-padding="false" :on-menu-opened="openSectionMenu" data-cy="summary-amount" custom-menu>
      <template v-slot:header v-if="showShareButton">
        <div class="header">
          <tiny-share-button class="card-share-button" v-bind="shareButtonProps" :share-impression="shareImpressionOptions.ON_CLICK"/>
        </div>
      </template>
      <div class="card-content">
        <div class="intro" :class="{ 'out-of-date': outOfDate }" v-if="intro">{{ intro }}</div>
        <div class="ri-bold-title" :class="{ 'out-of-date': outOfDate }"><slot name="title"/></div>
        <div class="amount ri-giant-display" :class="{ [variant]: true, 'out-of-date': outOfDate }">
          <span v-if="blocked">***</span>
          <template v-else>
            <animated-number v-if="useAnimatedNumber" :value="amount" :formatter="format"/>
            <span v-else>{{ format(amount) }}</span>
            <span> ₪</span>
            <card-action-button class="margin-top-m margin-bottom-xxs" v-if="showOshButton" @click="onOshClicked" text="ומה מצב העו״ש?"
                                :out-of-date="outOfDate"/>
          </template>
        </div>
      </div>
      <template v-slot:menuItems v-if="hasMenu">
        <summary-amount-section-menu :show-info-popups="shouldShowInfoMenuItem" />
      </template>
      <template v-slot:footer v-if="showFooter">
        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import TinyShareButton from '@/base-components/share/TinyShareButton';
import SummaryAmountSectionMenu from '@/base-components/cashflow/SummaryAmountSectionMenu';
import Segment from '@/Segment';
import shareUtils from '@/utils/share';
import InfoPopups
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/info-popups/InfoPopups';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';
import CardActionButton from '@/base-components/card-parts/CardActionButton';

export default {
  name: 'SummaryAmountCard',
  components: {
    CardActionButton,
    SummaryAmountSectionMenu,
    Card: BaseUI.Card,
    AnimatedNumber: BaseUI.AnimatedNumber,
    TinyShareButton,
  },
  mixins: [InfoPopups],
  data() {
    return {
      bottomSheetComponent: SimpleBottomSheetMenu,
    };
  },
  props: {
    intro: {
      type: String,
      required: false,
    },
    amount: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'black',
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    useAnimatedNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
    showOshButton: {
      type: Boolean,
      default: false,
    },
    shareButtonProps: {
      type: Object,
      default: () => undefined,
    },
  },
  computed: {
    shareImpressionOptions() {
      return shareUtils.SHARE_IMPRESSION_OPTIONS;
    },
    showShareButton() {
      return this.shareButtonProps;
    },
    shouldShowInfoMenuItem() {
      return this.shouldShowInfoPopups(this.previousMonth, this.blocked);
    },
    hasMenu() {
      return !this.isPreviewMode && !this.outOfDate && !this.blocked
          && !this.showShareButton && this.shouldShowInfoMenuItem;
    },
  },
  methods: {
    format(value) {
      const amount = Math.abs(Number(value));
      const result = amount.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      const stringResult = result.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      if (value < 0) {
        return `${stringResult}-`;
      }
      return stringResult;
    },
    openSectionMenu() {
      Segment.trackUserInteraction('OpenSectionMenuClicked', {
        cardTypeId: 'summaryAmount',
      });
    },
    onOshClicked() {
      if (this.isPreviewMode) {
        return;
      }
      Segment.trackUserInteraction('OshButtonFromSummaryAmountCard_Clicked', {
        amount: this.amount,
        oshFlagIsOn: this.oshFlagIsOn,
      });
      router.push({ path: '/balance' });
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '../../scss/mixins';

.summary-amount-card-wrapper {
  position: relative;
}

.summary-amount-card {
  position: relative;
  padding-top: 10px;

  .header {
    text-align: left;
    margin: 0 -14px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &.showing-edit-menu {
      opacity: 0.5;
    }

    .intro {
      @include cashflow-h2;
      margin-top: 10px;
      margin-bottom: $ri-spacing-s;
    }
    .amount {
      margin-top: $ri-spacing-ml;
    }

    .out-of-date {
      color: $riseup_gray_2;
    }

    .amount:not(.out-of-date) {
      color: $riseup_black;
      &.green {
        color: $riseup_green;
      }
      &.black {
        color: $riseup_black;
      }
      &.red {
        color: $riseup_danger_red;
      }
    }
  }

  .footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
</style>
