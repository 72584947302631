<template>
  <div class="sub-category-summary-header" :class="{ 'out-of-date': outOfDate }">
    <div class="section-row" v-bind:class="{ 'disabled-row': disabled }">
      <div class="label-wrapper" :class="[highlightCategory ? ['highlighted', cashflowCategory] : '']">
        <div class="label">
          <span class="label-text">{{ subCategory.shortLabel || subCategory.label }}</span>
        </div>
      </div>
      <div class="actuals" :class="[cashflowCategory]">
        <decimal-number :value="subCategory.actualsSum" />
        <span> ₪</span>
      </div>
      <div v-if="showRecommendedAmount" class="recommended">
        <div class="pencil-wrapper" v-if="canEditCategory" v-on:click="emitOpenEditAmountPopup">
          <img class="pencil" :src="require('@/assets/icons/gray-pencil.svg')">
        </div>
        <span class="recommended-amount" :class="{ 'clickable editable-number': canEditCategory }">
          <animated-decimal-number
              v-bind:value="subCategory.recommendedAmount"/>
          <span> ₪</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import constants from '@/constants/cashflow-view';
import CashflowAnalytics
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/CashflowAnalytics';
import TransactionDisplayUtils from '../../TransactionDisplayUtils.vue';
import AnimatedDecimalNumber from '../../../../../../../../../base-components/AnimatedDecimalNumber.vue';
import DecimalNumber from '../../../../../../../../../base-components/DecimalNumber.vue';
import EditAmountPopup from '../../../current-month/components/cashflow-edit/EditAmountPopup';

export default {
  name: 'SubCategorySummaryHeader',
  components: {
    AnimatedDecimalNumber,
    DecimalNumber,
  },
  mixins: [TransactionDisplayUtils, CashflowAnalytics],
  props: [
    'subCategory',
    'showRecommendedAmount',
    'canEditCategory',
    'cashflowCategory',
    'outOfDate',
  ],
  computed: {
    disabled() {
      return this.subCategory.disabled;
    },
    highlightCategory() {
      return this.subCategory.timeFrameDetails
          && this.subCategory.timeFrameDetails.timeFrame === constants.envelopeTimeFrames.weekly
          && this.subCategory.timeFrameDetails.isCurrentWeek;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    emitOpenEditAmountPopup() {
      if (this.canEditCategory) {
        this.openModal({
          component: EditAmountPopup,
          props: {
            expenseCategory: this.subCategory.parentCategory,
            category: this.subCategory,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import '../../../../../../../../../scss/mixins';
@import '../../../../../../../../../scss/category-color-mixins';

.sub-category-summary-header {
  .clickable {
    cursor: pointer;
  }

  .section-row {
    display: grid;
    width: 100%;
    grid-template-columns: 67px 1fr 1fr;
    column-gap: 10px;
    font-size: 14px;
    margin: 5px 0;
    align-items: center;
    height: 40px;

    .actuals {
      grid-column: 2/3;
      overflow: hidden;
      white-space: nowrap;
      font-weight: bold;
      @include category-color;
    }

    .recommended {
      height: 100%;
      grid-column: 3/4;
      display: flex;
      align-items: center;

      .recommended-amount {
        &.editable-number {
          color: $riseup_black;
        }
      }

      &.clickable {
        cursor: pointer;
      }

      .pencil-wrapper {
        padding-left: 4px;
        display: flex;
        .pencil {
          height: 14px;
          cursor: pointer;
        }
      }
    }

    .label-wrapper {
      grid-column: 1/2;
      padding-right: 7px;
      @include category-background-color;

      &.highlighted {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 0;
        color: $riseup_white;
        font-weight: bold;
        border-radius: 19px;
        width: calc(100% - 9px);
        height: 22px;
      }

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .label-text {
          white-space: nowrap;
        }
      }
    }

    &.disabled-row {
      div {
        color: $riseup_gray_2;
      }
      .actuals {
        color: $riseup_gray_2;
      }
      .recommended {
        .recommended-amount {
          &.editable-number {
            color: $riseup_black;
          }
        }
      }
    }
  }
  &.out-of-date {
    .section-row {
      .actuals {
        color: $riseup_gray_2;
      }
      .recommended {
        .recommended-amount {
          color: $riseup_gray_2;
        }
      }
      .label-wrapper {
        color: $riseup_gray_2;
        &.highlighted {
          background-color: $riseup_gray_2;
          color: $riseup_white;
        }
      }
    }

    &.disabled-row {
      .recommended {
        .recommended-amount {
          color: $riseup_gray_2;
        }
      }
    }
  }
}
</style>
