<template>
  <div class="active-challenge-badge">
    <lottie-player class="confetti-animation"
                   :src="JSON.stringify(require('@/assets/challenge/loties/Challenge-confeti.json'))"
                   preserveAspectRatio="xMinYMid slice"
                   autoplay
                   loop/>

    <span class="ri-small-body margin-top-xxs margin-left-xxs">באתגר</span>
    <img :src="require('@/assets/challenge/active-challenge.svg')">
  </div>
</template>

<script>
export default {
  name: 'ActiveChallengeBadge',

};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

      .active-challenge-badge {
        position: relative;
        color:$riseup_blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        align-content: flex-start;

        .confetti-animation {
          position: absolute;
          width: 130px;
          left: -15px;
          top: -6px;
          pointer-events: none; // to prevent the animation from blocking the click event on the expandable section
        }
      }

</style>
