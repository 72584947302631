<template>
  <div class="margin-top-s recommendation">
    <span class="ri-medium-display">{{ monthlySavingsRecommendation | formatAmountWithFraction(0) }}</span>
    <span class="ri-medium-headline">₪</span>
    <icon class="icon margin-right-xxs" icon-name="info" size="20px" :optical-size="20" @click="openRecommendationHelp"
          @keyup.enter="openRecommendationHelp" tabindex="0" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import RecommendationHelper from '../RecommendationHelper';

export default {
  name: 'SavingsRecommendation',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    monthlySavingsRecommendation: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openRecommendationHelp() {
      Segment.trackUserGot('MyProgress_Overview_RecommendationHelper_Clicked');
      this.openModal({
        component: RecommendationHelper,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/typography";

.recommendation {
  display: flex;
  align-items: flex-end;
  .icon {
    cursor: pointer;
    line-height: $ri-font-size-28;
  }
}
</style>
