<template>
  <div class="edit-tracking-category-popup">
    <colored-popup :class="variant" :back-action="back">

      <template v-slot:top-content>
        <div class="title">
          עריכת שם
        </div>
      </template>

      <template v-slot:bottom-content>
        <default-text-input class="category-name"
                            :label="'שם הקטגוריה'"
                            :value="updatedName"
                            @input="onNameChanged"/>
        <div class="button-container">
          <riseup-button :action="updateCategory"
                         :variant="'primary'"
                         :title="'סיימתי'"
                         :size="'slim'"
                         :loading="loading"
                         :disabled="updateDisabled"/>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import cashflowViewConsts from '@/constants/cashflow-view';
import trackingCategoryApi from '@/api/TrackingCategoriesApi';

export default {
  name: 'EditTrackingCategoryPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    DefaultTextInput: BaseUI.DefaultTextInput,
  },
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('EditTrackingCategory_Entered', { category: this.category });
    this.updatedName = this.categoryName;
  },
  data() {
    return {
      updateDisabled: true,
      updatedName: '',
      loading: false,
    };
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    variant() {
      return cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING];
    },
  },
  methods: {
    ...mapMutations('cashflowView', ['setCashflowUITree']),
    onNameChanged(value) {
      this.updatedName = value;
      this.updateDisabled = this.updatedName === '' || this.updatedName === this.categoryName;
    },
    updateCategoryNameOptimistic() {
      const updatedTrackingCategory = _.map(
        this.cashflowUITree.trackingCategory,
        c => (c.categoryId === this.category.categoryId ? { ...c, categoryName: this.updatedName, label: this.updatedName } : c),
      );
      this.setCashflowUITree({
        ...this.cashflowUITree,
        trackingCategory: updatedTrackingCategory,
      });
    },
    async updateCategory() {
      Segment.trackUserInteraction('EditTrackingCategoryPopup_Updated_Clicked', {
        updatedName: this.updatedName,
        envelopeId: this.category.envelopeId,
      });
      this.loading = true;
      try {
        await trackingCategoryApi.updateTrackingCategory({
          categoryId: this.category.categoryId,
          category: { name: this.updatedName },
        });
        this.updateCategoryNameOptimistic();
        DDLogs.log('EditTrackingCategoryPopup_Updated', {
          updatedName: this.updatedName,
          envelopeId: this.category.envelopeId,
        });
      } catch (error) {
        DDLogs.log('EditTrackingCategoryPopup_Updated_Failed', { envelopeId: this.category.envelopeId, error });
      }
      this.loading = false;
      this.$emit('close');
    },
    back() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.edit-tracking-category-popup {
  display: flex;
  flex-direction: column;
  text-align: right;

  ::v-deep .colored-top {
    color: $riseup_white;

    .title {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .category-name {
    margin-top: 6px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin-top: 26px;

    .question {
      margin-bottom: 13px;
      line-height: 20px;

      .bold {
        font-weight: bold;
      }
    }

    .transaction-list-container {

      .transaction-row-content {
        padding: 0;
      }

      .separator-wrapper {
        padding: 0;
      }
    }
  }

  > .separator {
    height: 1px;
  }

  .footer {
    .riseup-button {
      width: 100%;
    }
  }
}

</style>
