<template>
  <card class="my-progress-card margin-bottom-m padding-vertical-sm ri-body">
    <div>עדיין לא זיהינו הפקדה לחיסכון</div>
    <div class="flex-row full-width space-between content">
      <div class="flex-column space-between">
        <div class="ri-font-weight-bold">רוצה להתחיל לחסוך?</div>
        <div>
          <card-action-button text="חיסכון לטווח קצר"
                              class="margin-bottom-s full-width"
                              :background-color="ctaBackgroundColor"
                              :text-color="ctaTextColor"
                              borderColor="transparent"
                              @click="gotoShortTermSavings"/>
          <card-action-button text="חיסכון לטווח ארוך"
                              class="margin-bottom-xs full-width"
                              :background-color="ctaBackgroundColor"
                              :text-color="ctaTextColor"
                              borderColor="transparent"
                              @click="gotoLongTermSavings"/>
        </div>
      </div>
      <img src="/images/my-progress/confident.png" />
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import CardActionButton from '@/base-components/card-parts/CardActionButton.vue';

export default {
  name: 'SavingsCardEmptyState',
  components: {
    CardActionButton,
    Card: BaseUI.Card,
  },
  created() {
    Segment.trackUserGot('MyProgress_Savings_Card_Shown', { savingsExists: false });
  },
  data() {
    return { ctaBackgroundColor: BaseUI.Colors.riseupBlue, ctaTextColor: BaseUI.Colors.riseupWhite };
  },
  methods: {
    gotoShortTermSavings() {
      Segment.trackUserInteraction('MyProgress_SavingsCard_ShortTermSavings_Clicked');
      this.$router.push({ name: 'BalanceMoneyPage' });
    },
    gotoLongTermSavings() {
      Segment.trackUserInteraction('MyProgress_SavingsCard_LongTermSavings_Clicked');
      this.$router.push({ name: 'SavingZone' });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.my-progress-card {
  background-color: $riseup_white;

  .content {
    height: 150px;
  }

  img {
    height: 110px;
    width: 110px;
    align-self: flex-end;
  }
}

</style>
